import * as React from 'react';
import { Languages } from 'src/localization/Locale';
import { Log } from 'src/Logger';
import { SearchEngines } from 'src/models/AppSession';
import { CurrencyCodes, IDataPaging } from 'src/models/dto/DashboardModels';
import { ActionResult } from 'src/models/Result';
import { IResponse, ISocketResponse, Status } from 'src/network/Requests';
import { Wire } from 'src/network/Wire';
import { ProLibroMarkdown } from 'src/ui/foundation/Controls/proLibroMarkdown';
import { Notify } from 'src/ui/foundation/Messages';
import { Action, IBatch } from 'src/ui/foundation/StandaloneCogniflow';

export class Constants {
  static getAddNewConstant(): string {
    return "AddNew";
  }
}
export class Communication {
  static async handleResponse<T, R = any>(
    wire: Wire,
    url: string,
    request: any,
    onSuccess: (data: T, result: ActionResult, response?: IResponse) => any,
    onError?: (result: ActionResult, response?: IResponse) => any
  ): Promise<ActionResult> {
    let result: ActionResult<R> = ActionResult.create<R>();
    let response: IResponse | undefined;
    try {
      response = await wire.send(url, request);
      result.status = response.Status;
      if (response === undefined) {
        throw new Error("Couldn't get or deserialize response");
      }
    } catch (e) {
      result.status = Status.ConnectionFailed;
      result.addError({ Message: "Unable to connect to server" });
      return result;
    }

    if (result.status === Status.Success) {
      onSuccess(response.Data as T, result, response);
    } else {
      if (response.Error) {
        result.addError(response.Error);
      }
      if (onError !== undefined) {
        onError(result, response);
      }
    }

    return result.promise();
  }

  static handleSocket<T>(
    wire: Wire,
    socket: string,
    onMessage: (data: T, response?: ISocketResponse<T>) => any,
    onDisconnected?: () => any,
    onError?: (response: ISocketResponse) => any,
    refreshTimeout?: number
  ) {
    if (!refreshTimeout) {
      refreshTimeout = 500;
    }
    wire.createSocket(
      socket,
      (response: ISocketResponse) => {
        if (response.Status === Status.Success) {
          if (response.KeepAlive === false && onDisconnected !== undefined) {
            onDisconnected();
          }

          const message = response.Data as T;
          if (message !== null && message !== undefined) {
            onMessage(message, response);
          }
        } else {
          if (onError !== undefined) {
            onError(response);
          }
        }
      },
      refreshTimeout
    );
  }
}

export class Colors {
  static isDark(color: any): boolean {
    /* tslint:disable:no-bitwise */
    // Variables for red, green, blue values
    let r;
    let g;
    let b;
    let hsp;
    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
      // If HEX --> store the red, green, blue values in separate variables
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

      r = color[1];
      g = color[2];
      b = color[3];
    } else {
      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));

      r = color >> 16;
      g = (color >> 8) & 255;
      b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    // Using the HSP value, determine whether the color is light or dark
    return !(hsp > 127.5);
  }
}

export class Convert {
  static getCurrencySymbol(currCode: CurrencyCodes) {
    let retVal = CurrencyCodes[currCode];
    switch (currCode) {
      case CurrencyCodes.USD:
      case CurrencyCodes.CAD:
      case CurrencyCodes.AUD:
        retVal = "$";
        break;
      case CurrencyCodes.INR:
        retVal = "₹";
        break;
      case CurrencyCodes.GBP:
        retVal = "£";
        break;
      case CurrencyCodes.CNY:
      case CurrencyCodes.JPY:
        retVal = "¥";
        break;
    }
    return retVal;
  }
  static toMoney(currCode: CurrencyCodes, amount: number) {
    return (
      (Math.round(amount * 100) / 100)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + Convert.getCurrencySymbol(currCode)
    );
  }
  static formatDateForForm(date: Date) {
    return date.getUTCFullYear() + "-" + ("0" + (date.getUTCMonth() + 1)).slice(-2) + "-" + ("0" + date.getUTCDate()).slice(-2);
  }
  static formatDateForFormWithTime(date: Date) {
    let final =  new Date(date.toLocaleDateString() + " " + date.toLocaleTimeString() + " UTC");
    return final.toString();
  }
  static formatString(format: any, replacements: any[]) {
    let args = Array.prototype.slice.call(replacements, 0);
    return format.replace(/{(\d+)}/g, (match: any, number: any) => (typeof args[+number] !== "undefined" ? args[+number] : match));
  }
  static GetSearchURL(queryContent: string, searchEngine: SearchEngines): string {
    switch (searchEngine) {
      default:
      case SearchEngines.Google:
        return "https://www.google.com/search?q=" + queryContent;
      case SearchEngines.Bing:
        return "https://www.bing.com/search?q=" + queryContent;
      case SearchEngines.Wikipedia:
        return "https://en.wikipedia.org/w/index.php?search=" + queryContent;
      case SearchEngines.Yahoo:
        return "https://ca.search.yahoo.com/search?p=" + queryContent;
    }
  }

  static isEmptyOrSpaces(str: string | undefined) {
    if (str === undefined || str === null) {
      return true;
    }
    return str === null || /^ *$/.exec(str) !== null;
  }

  static dateToFormattedString(date: Date, lang: Languages): string {
    switch (lang) {
      default:
      case Languages.English:
        return new Intl.DateTimeFormat("en-GB", {
          year: "numeric",
          month: "long",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        })
          .format(new Date(date.toString()))
          .replace(", 00:00", "");
      case Languages.French:
        return new Intl.DateTimeFormat("fr-CA", {
          year: "numeric",
          month: "long",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        })
          .format(new Date(date.toString()))
          .replace(" 00 h 00", "");
    }
  }
  static dateToShortFormattedString(date: Date, lang: Languages): string {
    switch (lang) {
      default:
      case Languages.English:
        return new Intl.DateTimeFormat("en-GB", {
          year: "numeric",
          month: "long",
          day: "2-digit",
        }).format(new Date(date.toUTCString()));
      case Languages.French:
        return new Intl.DateTimeFormat("fr-CA", {
          year: "numeric",
          month: "long",
          day: "2-digit",
        }).format(new Date(date.toUTCString()));
    }
  }
  static indexify(resp: IBatch) {
    if (resp.Nodes.length === 0) {
      return resp;
    }
    let action = resp.Action;
    let items = resp.Nodes as IDataPaging[];
    let targetIndex = resp.TargetMainId;
    let batchSize = resp.BatchSize;
    switch (action) {
      case Action.append:
      case Action.insert:
      case Action.navAppend:
        targetIndex = targetIndex === 0 ? 0 : resp.TargetMainId;
        for (let i of items) {
          i.Index = targetIndex++;
          i.IsLast = false;
          i.IsFirst = false;
        }
        if (batchSize !== items.length) {
          items[items.length - 1].IsLast = true;
        }
        if (items[0].Index === 0) {
          items[0].IsFirst = true;
        }
        break;
      case Action.prepend:
        let holder = targetIndex - batchSize < 0 ? 0 : targetIndex - batchSize;
        for (let i of items) {
          i.Index = holder++;
          i.IsLast = false;
          i.IsFirst = false;
        }
        if (batchSize !== items.length || items[0].Index === 0) {
          items[0].IsFirst = true;
        }
        break;
    }
    resp.Nodes = items;
    return resp;
  }
}

export class IFrameUtils {
  /**
   * Adds an event listener to the whole website, regardless if you are in an IFrame or not.
   * By default, events binded to the document will not be applied to IFrames since they
   * have their own independant document.
   *
   * @param eventKey The name of the event. In must be the same in JavaScript and .
   * @param iframeBody A js reference on the element to which we want to bind the event in the IFrame.
   * @param handler The event handler that will be called when the event is trigerred.
   */
  static addGlobalEventListener(eventKey: string, iframeBody: HTMLElement, handler: (event: any) => void): void {
    document.addEventListener(eventKey, handler);
    iframeBody.addEventListener(eventKey, handler);
  }

  static removeGlobalEventListener(eventKey: string, iframeBody: HTMLElement, handler: (event: any) => void): void {
    document.removeEventListener(eventKey, handler);
    iframeBody.removeEventListener(eventKey, handler);
  }
}

interface IBinarySearchResult {
  wasValueFound: boolean;
  /** The closest value in the array that is <= the given value */
  closestLowerBound: number;
  /** The closest value in the array that is >= the given value */
  closestUpperBound: number;
}

export class Arrays {
  static binarySearch(array: number[], value: number, start: number, end: number): IBinarySearchResult {
    if (start > end) {
      return {
        wasValueFound: false,
        closestLowerBound: array[end],
        closestUpperBound: array[start],
      };
    }

    const mid = Math.floor((start + end) / 2);

    if (array[mid] === value) {
      return {
        wasValueFound: true,
        closestLowerBound: value,
        closestUpperBound: value,
      };
    }

    if (array[mid] > value) {
      return this.binarySearch(array, value, start, mid - 1);
    } else {
      return this.binarySearch(array, value, mid + 1, end);
    }
  }
  static base64ToArrayBuffer(base64: string) {
    // use regex to remove headers (data:image/png)
    if (base64.includes("data")) {
      base64 = base64.replace("data:", "").replace(/^.+,/, "");
    }
    let binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  static base64ToArray(base64: string) {
    let binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes;
  }
}

export class Books {
  /**
   * Finds the spine of the header containing the given segment. Segments can
   * either be an header or some part of the content of the book.
   *
   * @param spinesOfAllHeader A sorted list that contains the spine number of all headers.
   * @param spineOfSegment The spine of the segment.
   * @returns The spine of the header containing the given segment.
   */
  static findCorrespondingHeader(spinesOfAllHeaders: number[], spineOfSegment: number): number {
    const searchResult = Arrays.binarySearch(spinesOfAllHeaders, spineOfSegment, 0, spinesOfAllHeaders.length - 1);
    return searchResult.closestLowerBound;
  }
}

export class ResourcePlatform {
  /**
   * Opens a new window to the given address and focuses the new window.
   *
   * @param url The location of the content to display in the new window.
   */
  static openNewTab(url: string) {
    const win = window.open(url, "webMedia")!;
    if (win) {
      win.focus();
    }
  }
  /**
   * Opens a new window with the given content in it.
   *
   * @param data The data to display in the body tag.
   * @param title The title of the window.
   * @param head The data to display in the head tag. I can be CSS, JavaScript or anything else.
   * @param needsWrapping Does the data need to be wrapped with HTML, Head, Title and body tags?
   */
  static openNewTabWithContent(data: string, title: string, needsWrapping: boolean, head = ""): Window {
    const win = window.open("", "Media")!;
    if (win) {
      win.document.open();
      if (needsWrapping) {
        win.document.write(`<html><head>${head}</head><body><title>${title}</title>${data}</body></html>`);
      } else {
        win.document.write(data);
      }
      win.focus();
    }
    return win;
  }

  /**
   * Makes an ajax call to test the given urls. For each url, it will check if the call succeded. This function
   * returns the first url that succeded. The order is defined by index in the list, not by round trip time.
   * This method only covers GET requests without any specific headers.
   *
   * @param urls The list of url to test.
   * @returns The first url that gave a positive response, based on its HTTP status code. null if none is valid.
   */
  static async testUrls(...urls: string[]): Promise<string | null> {
    for (const url of urls) {
      try {
        Log.info(`url is ${url}`);
        const response = await fetch(url, {
          mode: "no-cors",
          headers: { "Access-Control-Allow-Origin": "*" },
        });
        Log.info(`returned url status was ${response.status}`);
        // response.status === 0 => Cannot check because of CORS policy. Accept URL.
        if (response.status === 0 || (response.status >= 200 && response.status < 400)) {
          return url;
        }
      } catch (err) {
        Log.error("Could not check URL", err);
        /* Ignore errors. CORS are considered as success but status code is 0. */
      }
    }

    return null;
  }

  /**
   * Validates that the given url will open in a new domain and opens
   * a new window with the given url. Urls with protocols specified (such as http and https)
   * will always open in a new domain (without automatically being prefixed by the current domain).
   *
   * @param url The location of the content to display in the new window.
   */
  static async openNewWindowInOtherDomain(url: string) {
    let validUrl: string | null;
    if (url.indexOf("http") === 0 || url.indexOf("https") === 0) {
      if (url.indexOf("https") === -1) {
        url = url.replace("http", "https");
      }
      validUrl = await ResourcePlatform.testUrls(url);
      if (!validUrl) {
        url = url.replace("https", "http");
        validUrl = await ResourcePlatform.testUrls(url);
      }
    } else {
      validUrl = await ResourcePlatform.testUrls("https://" + url, "http://" + url);
    }

    if (validUrl) {
      ResourcePlatform.openNewTab(validUrl);
    } else {
      Log.info(`Unable to open url '${url}' in a new window. The url could not return a positive http status code.`);
      Notify.error(`Unable to open this external website in a new window because the url no longer exists.`);
    }
  }
  static toMarkdown(value: string, header?: string) {
    let markdowns = (
      <React.Fragment>
        {header ? <dt>{header}</dt> : ""}
        <dd className="markdownContainer">
          <ProLibroMarkdown value={value} readonly onChange={() => {}} />
        </dd>
      </React.Fragment>
    );
    return <dl>{markdowns}</dl>;
  }
}
