import React, { useContext, useState } from "react";
import { Button, Form, FormGroup, Input, InputGroup, InputGroupText, Label } from "reactstrap";
import { Convert } from "src/utilities/Helpers";

import { Image } from "../foundation/Assets";
import { Icon, Loading } from "../foundation/Controls";
import * as Messages from "../foundation/Messages";
import { AppContext } from "../state/Contextes";

const AccountDeletionView: React.FC = () => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [loginName, setloginName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmedDecision, setConfirmedDecision] = useState(false);
  const [accountDeleteCompleted, setAccountDeleteCompleted] = useState(false);
  const appSession = useContext(AppContext);

  const handleDeleteAccount = () => {
    Messages.Dialog.confirm(
      "This action is irreversible and will delete all of your data including any purchased access, user content, and all identifying information.",
      "Are you sure you want to delete your account?",
      Messages.Dialog.Buttons.DeleteCancel
    ).then((res) => {
      if (res !== "true") {
        return;
      }
      setIsDeleting(true);
      appSession
        .deleteUserAccountForm({ Username: loginName, Password: password })
        .then((callResult) => {
          if (callResult.valid()) {
            setAccountDeleteCompleted(true);
          } else {
            Messages.Notify.error("An error occurred while deleting your account: " + callResult.errors[0].Message);
          }
          setIsDeleting(false);
        })
        .catch(() => {
          Messages.Notify.error("An error occurred while deleting your account. Please try again later.");
          setIsDeleting(false);
        });
    });
  };

  const handleUsernameChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    setloginName(arg.target.value);
  };

  const handlePasswordChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    setPassword(arg.target.value);
  };
  const handleConfirmDecisionChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    setConfirmedDecision(arg.target.checked);
  };

  const checkForm = Convert.isEmptyOrSpaces(loginName) || Convert.isEmptyOrSpaces(password);

  if (accountDeleteCompleted) {
    return (
      <div className="darkOverlay">
        <div className="deleteAccountView">
          <Loading theme="opaque" isLoading={isDeleting} status="Deleting Account...">
            <div className="resetContainer">
              <h2>
                <b>Your account has been deleted. Generally this is immediate, but may take up to 24hrs to propagate.</b>
              </h2>
            </div>
          </Loading>
        </div>
      </div>
    );
  }

  return (
    <div className="darkOverlay">
      <div className="deleteAccountView">
        <Loading theme="opaque" isLoading={isDeleting} status="Deleting Account...">
          <div className="resetContainer">
            <h3>Please enter your account credentials for deletion.</h3>
            <Form>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.card />} />
                  </InputGroupText>
                  <Input type="email" name="username" value={loginName} placeholder={"Email"} onChange={handleUsernameChanged} />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupText>
                    <Icon src={<Image.lock />} />
                  </InputGroupText>
                  <Input type="password" name="password" value={password} placeholder={"New password"} onChange={handlePasswordChanged} />
                </InputGroup>
              </FormGroup>
              <FormGroup switch className="consentSwitch">
                <Input disabled={checkForm} type="switch" role="switch" checked={confirmedDecision} onChange={handleConfirmDecisionChanged} />
                <Label check>
                  I consent that by checking this box and submitting this form that CogniLore Inc. will delete{" "}
                  <b>
                    all of my data including any purchased access, user content, and all identifying information.{" "}
                    <span style={{ color: "red" }}>This is irreversible.</span>
                  </b>
                </Label>
              </FormGroup>
              <FormGroup className="center">
                <Button onClick={handleDeleteAccount} outline color="danger" disabled={checkForm || !confirmedDecision}>
                  Delete my account
                </Button>
              </FormGroup>
            </Form>
          </div>
        </Loading>
      </div>
    </div>
  );
};
export default AccountDeletionView;
