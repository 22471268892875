import * as React from 'react';
import SVG from 'react-inlinesvg';
import address_book from 'src/assets/img/icons/address_book.svg';
import annotations from 'src/assets/img/icons/annotations.svg';
import annotationTypes from 'src/assets/img/icons/annotationTypes.svg';
import announcement from 'src/assets/img/icons/announcement.svg';
import archive from 'src/assets/img/icons/archive.svg';
import arrowback from 'src/assets/img/icons/arrow-back.svg';
import arrownext from 'src/assets/img/icons/arrow-next.svg';
import arrowprev from 'src/assets/img/icons/arrow-prev.svg';
import bell from 'src/assets/img/icons/bell.svg';
import book from 'src/assets/img/icons/book.svg';
import bookmark from 'src/assets/img/icons/bookmark.svg';
import books from 'src/assets/img/icons/books.svg';
import bulletin from 'src/assets/img/icons/bulletin.svg';
import cancel_search from 'src/assets/img/icons/cancel_search.svg';
import card from 'src/assets/img/icons/card.svg';
import cart from 'src/assets/img/icons/cart.svg';
import cash from 'src/assets/img/icons/cash.svg';
import certificate from 'src/assets/img/icons/certificate.svg';
import close from 'src/assets/img/icons/close.svg';
import comments from 'src/assets/img/icons/comments.svg';
import copy from 'src/assets/img/icons/copy.svg';
import customers from 'src/assets/img/icons/customers.svg';
import deleteResolved from 'src/assets/img/icons/deleteResolved.svg';
import delete_item from 'src/assets/img/icons/delete_item.svg';
import download from 'src/assets/img/icons/download.svg';
import empty from 'src/assets/img/icons/empty.svg';
import envelop from 'src/assets/img/icons/envelop.svg';
import exit from 'src/assets/img/icons/exit.svg';
import favmultioff from 'src/assets/img/icons/fav-multi-off.svg';
import favmultion from 'src/assets/img/icons/fav-multi-on.svg';
import favsingleoff from 'src/assets/img/icons/fav-single-off.svg';
import favsingleon from 'src/assets/img/icons/fav-single-on.svg';
import fav from 'src/assets/img/icons/fav.svg';
import favourite from 'src/assets/img/icons/favourite.svg';
import feedback from 'src/assets/img/icons/feedback.svg';
import files_empty from 'src/assets/img/icons/files_empty.svg';
import file_empty from 'src/assets/img/icons/file_empty.svg';
import filteroff from 'src/assets/img/icons/filter-off.svg';
import filteron from 'src/assets/img/icons/filter-on.svg';
import filter from 'src/assets/img/icons/filter.svg';
import folder from 'src/assets/img/icons/folder.svg';
import hamburger from 'src/assets/img/icons/hamburger.svg';
import help from 'src/assets/img/icons/help.svg';
import history from 'src/assets/img/icons/history.svg';
import hitnext from 'src/assets/img/icons/hit-next.svg';
import hitprev from 'src/assets/img/icons/hit-prev.svg';
import hit_next from 'src/assets/img/icons/hit_next.svg';
import hit_previous from 'src/assets/img/icons/hit_previous.svg';
import home from 'src/assets/img/icons/home.svg';
import info from 'src/assets/img/icons/info.svg';
import key4 from 'src/assets/img/icons/key4.svg';
import key5 from 'src/assets/img/icons/key5.svg';
import link from 'src/assets/img/icons/link.svg';
import location from 'src/assets/img/icons/location.svg';
import lock from 'src/assets/img/icons/lock.svg';
import media from 'src/assets/img/icons/media.svg';
import menu from 'src/assets/img/icons/menu.svg';
import menu7 from 'src/assets/img/icons/menu7.svg';
import next from 'src/assets/img/icons/next.svg';
import notemultioff from 'src/assets/img/icons/note-multi-off.svg';
import notemultion from 'src/assets/img/icons/note-multi-on.svg';
import noteon from 'src/assets/img/icons/note-on.svg';
import notesingleoff from 'src/assets/img/icons/note-single-off.svg';
import notesingleon from 'src/assets/img/icons/note-single-on.svg';
import note from 'src/assets/img/icons/note.svg';
import off from 'src/assets/img/icons/off.svg';
import pause from 'src/assets/img/icons/pause.svg';
import phone from 'src/assets/img/icons/phone.svg';
import play from 'src/assets/img/icons/play.svg';
import plus from 'src/assets/img/icons/plus.svg';
import prev from 'src/assets/img/icons/prev.svg';
import print from 'src/assets/img/icons/print.svg';
import products from 'src/assets/img/icons/products.svg';
import question6 from 'src/assets/img/icons/question6.svg';
import radio_off from 'src/assets/img/icons/radio_off.svg';
import radio_on from 'src/assets/img/icons/radio_on.svg';
import rank from 'src/assets/img/icons/rank.svg';
import reading from 'src/assets/img/icons/reading.svg';
import refresh from 'src/assets/img/icons/refresh.svg';
import save from 'src/assets/img/icons/save.svg';
import scrubber from 'src/assets/img/icons/scrubber.svg';
import search from 'src/assets/img/icons/search.svg';
import searchResult from 'src/assets/img/icons/searchResult.svg';
import settings from 'src/assets/img/icons/settings.svg';
import showmore from 'src/assets/img/icons/show-more.svg';
import sortdown from 'src/assets/img/icons/sort-down.svg';
import sortup from 'src/assets/img/icons/sort-up.svg';
import sort from 'src/assets/img/icons/sort.svg';
import starburst from 'src/assets/img/icons/starburst.svg';
import statsbars2 from 'src/assets/img/icons/stats-bars2.svg';
import table from 'src/assets/img/icons/table.svg';
import tag from 'src/assets/img/icons/tag.svg';
import target from 'src/assets/img/icons/target.svg';
import tip from 'src/assets/img/icons/tip.svg';
import toc from 'src/assets/img/icons/toc.svg';
import TOCbroom from 'src/assets/img/icons/TOCbroom.svg';
import TOCcheckboxchecked from 'src/assets/img/icons/TOCcheckboxchecked.svg';
import TOCcheckboxpartial from 'src/assets/img/icons/TOCcheckboxpartial.svg';
import TOCcheckboxunchecked from 'src/assets/img/icons/TOCcheckboxunchecked.svg';
import TOCcollapse from 'src/assets/img/icons/TOCcollapse.svg';
import TOCdash from 'src/assets/img/icons/TOCdash.svg';
import TOCExpand from 'src/assets/img/icons/TOCExpand.svg';
import TOClinked from 'src/assets/img/icons/TOClinked.svg';
import TOCmasterchecked from 'src/assets/img/icons/TOCmasterchecked.svg';
import TOCmasterunchecked from 'src/assets/img/icons/TOCmasterunchecked.svg';
import TOCminus from 'src/assets/img/icons/TOCminus.svg';
import TOCplus from 'src/assets/img/icons/TOCplus.svg';
import TOCunlinked from 'src/assets/img/icons/TOCunlinked.svg';
import url from 'src/assets/img/icons/url.svg';
import user from 'src/assets/img/icons/user.svg';
import versions from 'src/assets/img/icons/versions.svg';
import warning from 'src/assets/img/icons/warning.svg';
import warningResolved from 'src/assets/img/icons/warningResolved.svg';
import www from 'src/assets/img/icons/www.svg';
export class Image {

static address_book = (props:any) => {
	return (
		<SVG src={address_book} />
	)
};
static annotations = (props:any) => {
	return (
		<SVG src={annotations} />
	)
};
static annotationTypes = (props:any) => {
	return (
		<SVG src={annotationTypes} />
	)
};
static announcement = (props:any) => {
	return (
		<SVG src={announcement} />
	)
};
static archive = (props:any) => {
	return (
		<SVG src={archive} />
	)
};
static arrowback = (props:any) => {
	return (
		<SVG src={arrowback} />
	)
};
static arrownext = (props:any) => {
	return (
		<SVG src={arrownext} />
	)
};
static arrowprev = (props:any) => {
	return (
		<SVG src={arrowprev} />
	)
};
static bell = (props:any) => {
	return (
		<SVG src={bell} />
	)
};
static book = (props:any) => {
	return (
		<SVG src={book} />
	)
};
static bookmark = (props:any) => {
	return (
		<SVG src={bookmark} />
	)
};
static books = (props:any) => {
	return (
		<SVG src={books} />
	)
};
static bulletin = (props:any) => {
	return (
		<SVG src={bulletin} />
	)
};
static cancel_search = (props:any) => {
	return (
		<SVG src={cancel_search} />
	)
};
static card = (props:any) => {
	return (
		<SVG src={card} />
	)
};
static cart = (props:any) => {
	return (
		<SVG src={cart} />
	)
};
static cash = (props:any) => {
	return (
		<SVG src={cash} />
	)
};
static certificate = (props:any) => {
	return (
		<SVG src={certificate} />
	)
};
static close = (props:any) => {
	return (
		<SVG src={close} />
	)
};
static comments = (props:any) => {
	return (
		<SVG src={comments} />
	)
};
static copy = (props:any) => {
	return (
		<SVG src={copy} />
	)
};
static customers = (props:any) => {
	return (
		<SVG src={customers} />
	)
};
static deleteResolved = (props:any) => {
	return (
		<SVG src={deleteResolved} />
	)
};
static delete_item = (props:any) => {
	return (
		<SVG src={delete_item} />
	)
};
static download = (props:any) => {
	return (
		<SVG src={download} />
	)
};
static empty = (props:any) => {
	return (
		<SVG src={empty} />
	)
};
static envelop = (props:any) => {
	return (
		<SVG src={envelop} />
	)
};
static exit = (props:any) => {
	return (
		<SVG src={exit} />
	)
};
static favmultioff = (props:any) => {
	return (
		<SVG src={favmultioff} />
	)
};
static favmultion = (props:any) => {
	return (
		<SVG src={favmultion} />
	)
};
static favsingleoff = (props:any) => {
	return (
		<SVG src={favsingleoff} />
	)
};
static favsingleon = (props:any) => {
	return (
		<SVG src={favsingleon} />
	)
};
static fav = (props:any) => {
	return (
		<SVG src={fav} />
	)
};
static favourite = (props:any) => {
	return (
		<SVG src={favourite} />
	)
};
static feedback = (props:any) => {
	return (
		<SVG src={feedback} />
	)
};
static files_empty = (props:any) => {
	return (
		<SVG src={files_empty} />
	)
};
static file_empty = (props:any) => {
	return (
		<SVG src={file_empty} />
	)
};
static filteroff = (props:any) => {
	return (
		<SVG src={filteroff} />
	)
};
static filteron = (props:any) => {
	return (
		<SVG src={filteron} />
	)
};
static filter = (props:any) => {
	return (
		<SVG src={filter} />
	)
};
static folder = (props:any) => {
	return (
		<SVG src={folder} />
	)
};
static hamburger = (props:any) => {
	return (
		<SVG src={hamburger} />
	)
};
static help = (props:any) => {
	return (
		<SVG src={help} />
	)
};
static history = (props:any) => {
	return (
		<SVG src={history} />
	)
};
static hitnext = (props:any) => {
	return (
		<SVG src={hitnext} />
	)
};
static hitprev = (props:any) => {
	return (
		<SVG src={hitprev} />
	)
};
static hit_next = (props:any) => {
	return (
		<SVG src={hit_next} />
	)
};
static hit_previous = (props:any) => {
	return (
		<SVG src={hit_previous} />
	)
};
static home = (props:any) => {
	return (
		<SVG src={home} />
	)
};
static info = (props:any) => {
	return (
		<SVG src={info} />
	)
};
static key4 = (props:any) => {
	return (
		<SVG src={key4} />
	)
};
static key5 = (props:any) => {
	return (
		<SVG src={key5} />
	)
};
static link = (props:any) => {
	return (
		<SVG src={link} />
	)
};
static location = (props:any) => {
	return (
		<SVG src={location} />
	)
};
static lock = (props:any) => {
	return (
		<SVG src={lock} />
	)
};
static media = (props:any) => {
	return (
		<SVG src={media} />
	)
};
static menu = (props:any) => {
	return (
		<SVG src={menu} />
	)
};
static menu7 = (props:any) => {
	return (
		<SVG src={menu7} />
	)
};
static next = (props:any) => {
	return (
		<SVG src={next} />
	)
};
static notemultioff = (props:any) => {
	return (
		<SVG src={notemultioff} />
	)
};
static notemultion = (props:any) => {
	return (
		<SVG src={notemultion} />
	)
};
static noteon = (props:any) => {
	return (
		<SVG src={noteon} />
	)
};
static notesingleoff = (props:any) => {
	return (
		<SVG src={notesingleoff} />
	)
};
static notesingleon = (props:any) => {
	return (
		<SVG src={notesingleon} />
	)
};
static note = (props:any) => {
	return (
		<SVG src={note} />
	)
};
static off = (props:any) => {
	return (
		<SVG src={off} />
	)
};
static pause = (props:any) => {
	return (
		<SVG src={pause} />
	)
};
static phone = (props:any) => {
	return (
		<SVG src={phone} />
	)
};
static play = (props:any) => {
	return (
		<SVG src={play} />
	)
};
static plus = (props:any) => {
	return (
		<SVG src={plus} />
	)
};
static prev = (props:any) => {
	return (
		<SVG src={prev} />
	)
};
static print = (props:any) => {
	return (
		<SVG src={print} />
	)
};
static products = (props:any) => {
	return (
		<SVG src={products} />
	)
};
static question6 = (props:any) => {
	return (
		<SVG src={question6} />
	)
};
static radio_off = (props:any) => {
	return (
		<SVG src={radio_off} />
	)
};
static radio_on = (props:any) => {
	return (
		<SVG src={radio_on} />
	)
};
static rank = (props:any) => {
	return (
		<SVG src={rank} />
	)
};
static reading = (props:any) => {
	return (
		<SVG src={reading} />
	)
};
static refresh = (props:any) => {
	return (
		<SVG src={refresh} />
	)
};
static save = (props:any) => {
	return (
		<SVG src={save} />
	)
};
static scrubber = (props:any) => {
	return (
		<SVG src={scrubber} />
	)
};
static search = (props:any) => {
	return (
		<SVG src={search} />
	)
};
static searchResult = (props:any) => {
	return (
		<SVG src={searchResult} />
	)
};
static settings = (props:any) => {
	return (
		<SVG src={settings} />
	)
};
static showmore = (props:any) => {
	return (
		<SVG src={showmore} />
	)
};
static sortdown = (props:any) => {
	return (
		<SVG src={sortdown} />
	)
};
static sortup = (props:any) => {
	return (
		<SVG src={sortup} />
	)
};
static sort = (props:any) => {
	return (
		<SVG src={sort} />
	)
};
static starburst = (props:any) => {
	return (
		<SVG src={starburst} />
	)
};
static statsbars2 = (props:any) => {
	return (
		<SVG src={statsbars2} />
	)
};
static table = (props:any) => {
	return (
		<SVG src={table} />
	)
};
static tag = (props:any) => {
	return (
		<SVG src={tag} />
	)
};
static target = (props:any) => {
	return (
		<SVG src={target} />
	)
};
static tip = (props:any) => {
	return (
		<SVG src={tip} />
	)
};
static toc = (props:any) => {
	return (
		<SVG src={toc} />
	)
};
static TOCbroom = (props:any) => {
	return (
		<SVG src={TOCbroom} />
	)
};
static TOCcheckboxchecked = (props:any) => {
	return (
		<SVG src={TOCcheckboxchecked} />
	)
};
static TOCcheckboxpartial = (props:any) => {
	return (
		<SVG src={TOCcheckboxpartial} />
	)
};
static TOCcheckboxunchecked = (props:any) => {
	return (
		<SVG src={TOCcheckboxunchecked} />
	)
};
static TOCcollapse = (props:any) => {
	return (
		<SVG src={TOCcollapse} />
	)
};
static TOCdash = (props:any) => {
	return (
		<SVG src={TOCdash} />
	)
};
static TOCExpand = (props:any) => {
	return (
		<SVG src={TOCExpand} />
	)
};
static TOClinked = (props:any) => {
	return (
		<SVG src={TOClinked} />
	)
};
static TOCmasterchecked = (props:any) => {
	return (
		<SVG src={TOCmasterchecked} />
	)
};
static TOCmasterunchecked = (props:any) => {
	return (
		<SVG src={TOCmasterunchecked} />
	)
};
static TOCminus = (props:any) => {
	return (
		<SVG src={TOCminus} />
	)
};
static TOCplus = (props:any) => {
	return (
		<SVG src={TOCplus} />
	)
};
static TOCunlinked = (props:any) => {
	return (
		<SVG src={TOCunlinked} />
	)
};
static url = (props:any) => {
	return (
		<SVG src={url} />
	)
};
static user = (props:any) => {
	return (
		<SVG src={user} />
	)
};
static versions = (props:any) => {
	return (
		<SVG src={versions} />
	)
};
static warning = (props:any) => {
	return (
		<SVG src={warning} />
	)
};
static warningResolved = (props:any) => {
	return (
		<SVG src={warningResolved} />
	)
};
static www = (props:any) => {
	return (
		<SVG src={www} />
	)
};}
