/* eslint-disable @typescript-eslint/no-implied-eval */

import * as React from "react";
import { Alert, Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { AsyncQueue } from "src/collections/AsyncQueue";
import { Single, Table } from "src/collections/Observable";
import _ from "underscore";

export interface INotificationOptions {
  duration: NotificationDuration | number;
  type: NotificationType;
  dismissable: boolean;
}

export enum NotificationDuration {
  short = 1000,
  normal = 2500,
  long = 3500,
  never = -1,
}

export enum NotificationType {
  default = "primary",
  success = "success",
  danger = "danger",
  warning = "warning",
  info = "info",
}

export interface INotification {
  id: string;
  content: string | React.ReactNode;
  dismissable: boolean;
  delay: number;
  color: string;
}

export class NotificationList {
  notifications: Table<INotification>;

  constructor() {
    this.notifications = new Table<INotification>((n: INotification) => n.id);
  }

  add(data: INotification): string {
    data.id = "_" + Math.random().toString(36).substr(2, 9);
    this.notifications.insertOrUpdate(data);
    return data.id;
  }

  remove(id: string) {
    if (this.notifications.exists(id)) {
      this.notifications.deleteById(id);
    }
  }

  modify(id: string, newData: INotification) {
    if (this.notifications.exists(id)) {
      this.notifications.insertOrUpdate(newData);
    }
  }
}

export class Notify {
  static notificationList = new NotificationList();

  static default(content: string | React.ReactNode, options?: INotificationOptions): string {
    return Notify.notify(content, _.defaults(options, { type: NotificationType.default }) as INotificationOptions);
  }

  static success(content: string | React.ReactNode, options?: INotificationOptions): string {
    return Notify.notify(content, _.defaults(options, { type: NotificationType.success }) as INotificationOptions);
  }

  static error(content: string | React.ReactNode, options?: INotificationOptions): string {
    return Notify.notify(content, _.defaults(options, { type: NotificationType.danger }) as INotificationOptions);
  }

  static warning(content: string | React.ReactNode, options?: INotificationOptions): string {
    return Notify.notify(content, _.defaults(options, { type: NotificationType.warning }) as INotificationOptions);
  }

  static info(content: string | React.ReactNode, options?: INotificationOptions): string {
    return Notify.notify(content, _.defaults(options, { type: NotificationType.info }) as INotificationOptions);
  }

  static notify(content: string | React.ReactNode, options?: INotificationOptions): string {
    const defaultOptions: INotificationOptions = {
      type: NotificationType.default,
      duration: NotificationDuration.normal,
      dismissable: true,
    };

    let opt = _.defaults(options, defaultOptions);
    opt.content = content;
    if (this.notificationList) {
      return this.notificationList.add({
        id: "",
        content: opt.content,
        delay: opt.duration,
        dismissable: opt.dismissable,
        color: opt.type,
      });
    }
    return "";
  }

  static remove(id: string) {
    if (this.notificationList) {
      this.notificationList.remove(id);
    }
  }
}

interface INotificationContainerProps {
  notifications: INotification[];
}

interface INotificationContainerState {}

export class NotificationContainer extends React.PureComponent<INotificationContainerProps, INotificationContainerState> {
  render() {
    return (
      <div className="notification-container">
        <div className="notification-list">
          {Object.values(this.props.notifications).map((item: INotification) => (
            <NotificationItem key={item.id} {...item} />
          ))}
        </div>
      </div>
    );
  }
}

interface INotificationItemProps extends INotification {}

interface INotificationItemState {
  visible: boolean;
}
export class NotificationItem extends React.Component<INotificationItemProps, INotificationItemState> {
  private _timer: NodeJS.Timeout | null;
  constructor(props: INotificationItemProps | Readonly<INotificationItemProps>) {
    super(props);
    this.state = {
      visible: true,
    };
    this.onDismiss = this.onDismiss.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  setTimer() {
    if (this.props.delay >= 0) {
      if (this._timer != null) {
        clearTimeout(this._timer);
      }

      this._timer = setTimeout(
        function (this: NotificationItem) {
          this.close();
          this._timer = null;
        }.bind(this),
        this.props.delay
      );
    }
  }

  private close() {
    this.setState({ visible: false });
    setTimeout(
      function (this: NotificationItem) {
        Notify.remove(this.props.id);
      }.bind(this),
      2000
    );
  }

  clearTimer() {
    if (this._timer != null) {
      clearTimeout(this._timer);
    }
  }

  componentDidMount() {
    this.setTimer();
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  onDismiss() {
    this.close();
  }

  onMouseEnter() {
    this.clearTimer();
  }

  onMouseLeave() {
    this.setTimer();
  }

  render() {
    return (
      <Alert color={this.props.color} isOpen={this.state.visible} toggle={this.onDismiss} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        {this.props.content}
      </Alert>
    );
  }
}

export enum DialogButtonColor {
  default = "primary",
  success = "success",
  danger = "danger",
}

interface IDialogButton {
  label: string;
  action: string | number;
  color?: string;
  isCancel?: boolean;
  isDefault?: boolean;
}

type DialogButtonCollection = IDialogButton[];

export enum DialogResult {
  ok = "ok",
  yes = "true",
  no = "false",
  delete = "true",
  cancel = "false",
}

export interface IDialogOptions {
  title?: string | React.ReactNode;
  content?: string | React.ReactNode;
  buttons?: DialogButtonCollection;
}

export class Dialog {
  static currentDialog = new Single<IDialogItemProps | undefined>(undefined);

  static Buttons = class {
    static Ok: DialogButtonCollection = new Array<IDialogButton>({
      label: "Ok",
      action: DialogResult.ok,
      color: DialogButtonColor.default,
      isCancel: true,
      isDefault: true,
    });
    static YesNo: DialogButtonCollection = new Array<IDialogButton>(
      {
        label: "Yes",
        action: DialogResult.yes,
        color: DialogButtonColor.default,
        isDefault: true,
      },
      {
        label: "No",
        action: DialogResult.no,
        isCancel: true,
      }
    );
    static DeleteCancel: DialogButtonCollection = new Array<IDialogButton>(
      {
        label: "Delete",
        action: DialogResult.delete,
        color: DialogButtonColor.danger,
        isDefault: true,
      },
      {
        label: "Cancel",
        action: DialogResult.cancel,
        isCancel: true,
      }
    );
    static None: DialogButtonCollection = new Array<IDialogButton>();
  };

  static alert(message: string | React.ReactNode, title?: string | React.ReactNode, buttons = Dialog.Buttons.Ok): Promise<string | number> {
    return Dialog.build({
      title: title,
      content: message,
      buttons: buttons,
    }).create();
  }

  static confirm(message: string | React.ReactNode, title?: string | React.ReactNode, buttons = Dialog.Buttons.YesNo): Promise<string | number> {
    return Dialog.build({
      title: title,
      content: message,
      buttons: buttons,
    }).create();
  }

  private static queue: AsyncQueue<IDialogOptions> = new AsyncQueue<IDialogOptions>();
  static build(options: IDialogOptions): { create: () => Promise<string | number> } {
    return {
      create: () =>
        new Promise<any>(async (resolve) => {
          options = await this.queue.enqueue(options);
          let dialogItemProps: IDialogItemProps = {
            title: options.title,
            content: options.content,
            onResult: (result: string | number) => {
              resolve(result);
            },
            onClosed: () => {
              this.queue.dequeue();
            },
            buttons: options.buttons || new Array<IDialogButton>(),
          };
          if (this.currentDialog) {
            // We have to reset the dialog to undefined in order for it to be shown once again.
            this.currentDialog.set(undefined);
            setTimeout(() => {
              this.currentDialog.set(dialogItemProps);
            }, 500);
          }
        }),
    };
  }
}

export interface IDialogContainerProps {
  current: IDialogItemProps | undefined;
}

export interface IDialogContainerState {}

export class DialogContainer extends React.Component<IDialogContainerProps, IDialogContainerState> {
  render() {
    if (this.props.current && this.props.current.buttons === Dialog.Buttons.DeleteCancel) {
      return <div className="message-dialog-container">{this.props.current && <DeleteConfirmDialog {...this.props.current} />}</div>;
    } else {
      return <div className="message-dialog-container">{this.props.current && <DialogItem {...this.props.current} />}</div>;
    }
  }
}

export interface IDialogItemProps {
  title: string | React.ReactNode;
  content: string | React.ReactNode;
  onResult: (result: string | number) => any;
  onClosed?: () => any;
  buttons: DialogButtonCollection;
}

export interface IDialogItemState {
  isOpen: boolean;
}

export class DialogItem extends React.Component<IDialogItemProps, IDialogItemState> {
  constructor(props: IDialogItemProps | Readonly<IDialogItemProps>) {
    super(props);
    this.state = {
      isOpen: true,
    };
    this.onButtonClick = this.onButtonClick.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onKeydown = this.onKeydown.bind(this);
  }

  onButtonClick(action: string | number) {
    this.props.onResult(action);
    this.setState({
      isOpen: false,
    });
  }

  onKeydown(event: any) {
    if (event.keyCode && event.keyCode === 13) {
      // Press on Enter
      if (this.state.isOpen) {
        for (let button of this.props.buttons) {
          if (button.isDefault === true) {
            this.onButtonClick(button.action);
            break;
          }
        }
      }
    }
  }

  onToggle() {
    if (this.state.isOpen) {
      for (let button of this.props.buttons) {
        if (button.isCancel === true) {
          this.onButtonClick(button.action);
          break;
        }
      }
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onKeydown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeydown);
  }

  render() {
    let hasCancel = false;
    for (let button of this.props.buttons) {
      if (button.isCancel === true) {
        hasCancel = true;
        break;
      }
    }
    return (
      <Modal isOpen={this.state.isOpen} className="message-dialog-item" onClosed={this.props.onClosed} toggle={hasCancel ? this.onToggle : undefined}>
        <ModalHeader toggle={hasCancel ? this.onToggle : undefined}>{this.props.title}</ModalHeader>
        {this.props.content && <ModalBody>{this.props.content}</ModalBody>}
        {this.props.buttons && this.props.buttons.length > 0 && (
          <ModalFooter>
            {this.props.buttons.map((button: IDialogButton) => (
              <Button autoFocus={button.isDefault} key={button.action} color={button.color || "secondary"} onClick={() => this.onButtonClick(button.action)}>
                {button.label}
              </Button>
            ))}
          </ModalFooter>
        )}
      </Modal>
    );
  }
}

export interface IDeleteConfirmDialogItemProps {
  title: string | React.ReactNode;
  content: string | React.ReactNode;
  onResult: (result: string | number) => any;
  onClosed?: () => any;
  buttons: DialogButtonCollection;
}

export interface IDeleteConfirmDialogItemState {
  isOpen: boolean;
  deleteValidator: string;
}
export class DeleteConfirmDialog extends React.Component<IDeleteConfirmDialogItemProps, IDeleteConfirmDialogItemState> {
  constructor(props: IDeleteConfirmDialogItemProps | Readonly<IDeleteConfirmDialogItemProps>) {
    super(props);
    this.state = {
      isOpen: true,
      deleteValidator: "",
    };
    this.onButtonClick = this.onButtonClick.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onKeydown = this.onKeydown.bind(this);
  }

  onButtonClick(action: string | number) {
    if (action === "true" && this.state.deleteValidator !== "DELETE") {
      return;
    }

    this.props.onResult(action);
    this.setState({
      isOpen: false,
    });
  }

  onKeydown(event: any) {
    if (event.keyCode && event.keyCode === 13) {
      // Press on Enter
      if (this.state.isOpen) {
        for (let button of this.props.buttons) {
          if (button.isDefault === true) {
            this.onButtonClick(button.action);
            break;
          }
        }
      }
    }
  }

  onToggle() {
    if (this.state.isOpen) {
      for (let button of this.props.buttons) {
        if (button.isCancel === true) {
          this.onButtonClick(button.action);
          break;
        }
      }
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onKeydown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeydown);
  }
  render() {
    let hasCancel = false;
    for (let button of this.props.buttons) {
      if (button.isCancel === true) {
        hasCancel = true;
        break;
      }
    }
    return (
      <Modal
        isOpen={this.state.isOpen}
        className="message-dialog-item deleteConfirm"
        onClosed={this.props.onClosed}
        toggle={hasCancel ? this.onToggle : undefined}
      >
        <ModalHeader toggle={hasCancel ? this.onToggle : undefined}>{this.props.title}</ModalHeader>
        {this.props.content && (
          <ModalBody>
            {this.props.content}
            <p>
              <b>This cannot be undone.</b> To confirm, write &quot;DELETE&quot; in the field below and click &quot;Delete&quot;.
            </p>
            <Input type="text" placeholder="Write 'DELETE'" onChange={(e) => this.setState({ deleteValidator: e.target.value })} />
          </ModalBody>
        )}

        {this.props.buttons && this.props.buttons.length > 0 && (
          <ModalFooter>
            {this.props.buttons.map((button: IDialogButton) => (
              <Button
                disabled={this.state.deleteValidator !== "DELETE" && button.isDefault}
                autoFocus={button.isDefault}
                key={button.action}
                color={button.color || "secondary"}
                onClick={() => this.onButtonClick(button.action)}
              >
                {button.label}
              </Button>
            ))}
          </ModalFooter>
        )}
      </Modal>
    );
  }
}
