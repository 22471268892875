import 'react-datepicker/dist/react-datepicker.css';

import React from 'react';
import { Dropdown, Tab, Tabs } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Languages } from 'src/localization/Locale';
import { AppSession } from 'src/models/AppSession';
import { CommentState } from 'src/models/dto/AnnouncementRequest';
import * as Models from 'src/models/dto/DashboardModels';
import { AnnouncementAttachmentForm } from 'src/ui/foundation/Controls/AnnouncementAttachmentForm';
import { TitleSelector } from 'src/ui/foundation/Controls/TitleSelector';
import { DataItem, DataRow, DataTable } from 'src/ui/foundation/DataTable';
import { AppContext } from 'src/ui/state/Contextes';
import { Convert } from 'src/utilities/Helpers';

import { ProLibroMarkdown } from '../../foundation/Controls/proLibroMarkdown';
import { Action, INode, IRequest, IResponse } from '../../foundation/StandaloneCogniflow';
import { CommentForm } from './CommentForm';

enum AnnouncementTab {
  Info,
  Moderation,
  Titles,
  Attachments,
}

export interface IAnnouncementFormProps {
  initialNode: Models.IAnnouncementViewModel;
  isEditing: boolean;
  saveRequested?: (
    node: Models.IAnnouncementViewModel,
    attachments: Models.IAnnouncementAttachment[],
    allTags: string[],
    selectedTags: string[],
    titles: Models.ITitle[]
  ) => void;
  deleteRequested?: (node: Models.IAnnouncement) => void;
  reloadAnnouncements?: () => void;
}

export interface IAnnouncementFormState {
  editingNode: Models.IAnnouncementViewModel;
  currentAttachments: Models.IAnnouncementAttachment[];
  loading: boolean;
  formattedPublishBy: Date | null;
  currentTitles: Models.ITitle[];
  allTags: string[];
  selectedTags: string[];
  activeTab: AnnouncementTab;
  commentMode: boolean;
  editingComment: Models.ICommentViewModel | null;
}

export class AnnouncementForm extends React.Component<IAnnouncementFormProps, IAnnouncementFormState> {
  context: AppSession;
  static contextType = AppContext;
  commentsTable = React.createRef<DataTable>();

  constructor(props: IAnnouncementFormProps) {
    super(props);
    this.state = {
      currentAttachments: [] as Models.IAnnouncementAttachment[],
      loading: false,
      formattedPublishBy: new Date(props.initialNode.Announcement.PublishBy),
      currentTitles: props.initialNode.AssociatedTitles ?? [],
      allTags: props.initialNode.AllTags,
      selectedTags: props.initialNode.SelectedTags,
      activeTab: AnnouncementTab.Info,
      commentMode: false,
      editingComment: null,
      editingNode: {
        Index: props.initialNode.Index,
        IsFirst: props.initialNode.IsFirst,
        IsLast: props.initialNode.IsLast,
        Comments: props.initialNode.Comments,
        Rating: props.initialNode.Rating,
        NumberOfImpressions: props.initialNode.NumberOfImpressions,
        Announcement: props.initialNode.Announcement,
        AllTags: props.initialNode.AllTags,
        SelectedTags: props.initialNode.SelectedTags,
        AssociatedTitles: props.initialNode.AssociatedTitles,
      },
    };
    this.tagsChanged = this.tagsChanged.bind(this);
  }

  async componentDidMount() {
    if (this.props.isEditing) {
      let titRes = await this.context.getAnnouncementAssociatedTitles({ TableId: this.state.editingNode.Announcement.TableId });
      let attRes = await this.context.getAnnouncementAttachments({
        TableId: this.state.editingNode.Announcement.TableId,
      });

      if (titRes.valid() && attRes.valid()) {
        this.setState({ currentAttachments: attRes.data.Attachments, currentTitles: titRes.data.Titles });
      }
    }
  }

  titleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Announcement: {
          ...prevState.editingNode.Announcement,
          Title: value,
        },
      },
    }));
  };

  handleMDEditorInput = (val?: string) => {
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Announcement: {
          ...prevState.editingNode.Announcement,
          Content: val ?? "",
        },
      },
    }));
  };

  publicationDateChanged = (date: Date) => {
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Announcement: {
          ...prevState.editingNode.Announcement,
          PublishBy: date,
        },
      },
    }));
    this.setState({ formattedPublishBy: date });
  };

  tagsChanged(allTags: string[], selectedTags: string[]) {
    this.setState({
      editingNode: {
        ...this.state.editingNode,
        AllTags: allTags,
        SelectedTags: selectedTags,
      },
    });
  }

  ratingScoreVisibleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Announcement: {
          ...prevState.editingNode.Announcement,
          AllowScoreDisplay: e.target.checked,
        },
      },
    }));
  };

  allowCommentsChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Announcement: {
          ...prevState.editingNode.Announcement,
          AllowComments: e.target.checked,
        },
      },
    }));
  };

  saveAnnouncement = () => {
    if (this.props.saveRequested) {
      this.props.saveRequested(this.state.editingNode, this.state.currentAttachments, this.state.allTags, this.state.selectedTags, this.state.currentTitles);
    }
  };

  handleNewAttachment = (e: Models.IAnnouncementAttachment) => {
    let current = JSON.parse(JSON.stringify(this.state.currentAttachments));
    current.push(e);
    this.setState({ currentAttachments: current });
  };
  handleUpdatedAttachment = (e: Models.IAnnouncementAttachment) => {
    let current = JSON.parse(JSON.stringify(this.state.currentAttachments));
    current.splice(
      this.state.currentAttachments.findIndex((x) => x.TableId === e.TableId),
      1
    );
    current.push(e);
    this.setState({ currentAttachments: current });
  };
  deleteAttachment = (e: Models.IAnnouncementAttachment) => {
    let current = JSON.parse(JSON.stringify(this.state.currentAttachments));
    current.splice(
      this.state.currentAttachments.findIndex((x) => x.Index === e.Index),
      1
    );
    this.setState({ currentAttachments: current });
  };

  titlesChanged = (titles: Models.ITitle[]) => {
    this.setState((prevState) => ({ ...prevState, currentTitles: titles }));
  };

  private commentFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowCommentsForAnnouncement({
        FlowRequest: request.Batches[0],
        AnnouncementId: this.state.editingNode.Announcement.TableId,
      });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
        reject();
      }
    });

  private initializeComments = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      if (this.state.editingNode.Announcement.TableId === -1) {
        reject();
        return;
      }
      let result = await this.context.flowCommentsForAnnouncement({
        FlowRequest: {
          Action: Action.insert,
          AnchorMainId: 0,
          Nodes: [],
          BatchSize: 75,
          TargetMainId: 0,
          Query: query,
        },
        AnnouncementId: this.state.editingNode.Announcement.TableId,
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });

  selectComment = (n: INode) => {
    this.setState({
      editingComment: n as Models.ICommentViewModel,
      commentMode: true,
    });
  };
  private generateComment = (n: INode) => {
    let node = n as Models.ICommentViewModel;
    let dataItems = [];
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;

    dataItems.push(<DataItem flexVal={1} className="" key={1} value={node.Comment.UserId.toString()} />);
    dataItems.push(<DataItem flexVal={1} className={""} key={2} value={node.Comment.UserName} />);
    dataItems.push(<DataItem flexVal={2} className="" key={3} value={node.Comment.Content} />);
    dataItems.push(<DataItem flexVal={1} className="" key={4} value={Convert.dateToFormattedString(node.Comment.CreationDate, Languages.English)} />);
    dataItems.push(<DataItem flexVal={1} className="" key={5} value={CommentState[node.Comment.CommentState]} />);
    return (
      <DataRow
        className={this.state.editingComment?.Index === node.Index ? " selected" : ""}
        node={node}
        key={node.Index}
        attributes={attrs}
        dataItems={dataItems}
        rowEditRequested={this.selectComment}
      />
    );
  };

  private reloadCommentAndDismiss = () => {
    this.commentsTable.current!.reload();
    this.setState({
      editingComment: null,
      commentMode: false,
    });
  };

  formatDate(date: Date) {
    let parts = date.toString().split(/[-T:.]/);
    let dato = new Date(Date.UTC(+parts[0], +parts[1] - 1, +parts[2], +parts[3], +parts[4], +parts[5]));
    return dato;
  }

  private getPublishDate() {
    if (this.props.isEditing) {
      return this.state.formattedPublishBy === null ? this.formatDate(this.state.editingNode.Announcement.PublishBy) : this.state.formattedPublishBy;
    }
    return this.state.formattedPublishBy ? this.state.formattedPublishBy : new Date();
  }

  render() {
    if (this.state.commentMode && this.state.editingComment !== null) {
      let commentModel: Models.ICommentViewModel = this.state.editingComment;
      return (
        <CommentForm
          initialNode={commentModel}
          reloadComment={this.reloadCommentAndDismiss}
          goBackButtonClicked={() =>
            this.setState({
              editingComment: null,
              commentMode: false,
            })
          }
        />
      );
    }

    return (
      <div className="full-width full-height">
        <Tabs defaultActiveKey={this.state.activeTab} id="announcementTabs">
          <Tab eventKey={AnnouncementTab.Info} title={"Info"}>
            <Form>
              <Col>
                <Row>
                  <FormGroup style={{ flex: "1" }}>
                    <Label for="title">Title </Label>
                    <Input
                      onChange={this.titleChanged}
                      value={
                        this.state.editingNode.Announcement.Title.length <= 100
                          ? this.state.editingNode.Announcement.Title
                          : this.state.editingNode.Announcement.Title.slice(0, 100) + "..."
                      }
                      type="text"
                      name="title"
                      id="title"
                      width="8px"
                    />
                  </FormGroup>
                </Row>
                <Row>
                  <FormGroup style={{ flex: "1", marginLeft: "35px" }}>
                    <Label check for="allowComments">
                      <Input
                        onChange={this.allowCommentsChanged}
                        checked={this.state.editingNode.Announcement.AllowComments}
                        type="checkbox"
                        name="allowComments"
                        id="allowComments"
                      />
                      Allow Comments
                    </Label>
                  </FormGroup>
                  <FormGroup style={{ flex: "1", marginLeft: "35px" }}>
                    <Label check for="ratingScoreVisible">
                      <Input
                        onChange={this.ratingScoreVisibleChanged}
                        checked={this.state.editingNode.Announcement.AllowScoreDisplay}
                        type="checkbox"
                        name="ratingScoreVisible"
                        id="ratingScoreVisible"
                      />
                      Show Rating Score
                    </Label>
                  </FormGroup>
                </Row>
                <Row>
                  <FormGroup style={{ flex: "1" }}>
                    <Label style={{ marginRight: "10px" }}>Publish By </Label>
                    <DatePicker
                      showTimeSelect
                      dateFormat="yyyy-MM-dd HH:mm:ss"
                      timeFormat="HH:mm:ss"
                      selected={this.getPublishDate()}
                      onChange={this.publicationDateChanged}
                    />
                  </FormGroup>
                </Row>
                <Row>
                  <FormGroup>
                    <TagSelectDropdown currentAnnouncement={this.state.editingNode} tagsChanged={this.tagsChanged} />
                  </FormGroup>
                </Row>

                {<ProLibroMarkdown value={this.state.editingNode.Announcement.Content} onChange={this.handleMDEditorInput} />}

              </Col>
            </Form>
          </Tab>
          {this.props.isEditing && (
            <Tab eventKey={AnnouncementTab.Moderation} title={"Moderation"}>
              <DataTable
                headers={["User ID", "User Name", "Comment", "Date Posted", "State"]}
                headerFlexes={[1, 1, 2, 1, 1]}
                flowProvider={this.commentFlowProvider}
                initializeFlowProvider={this.initializeComments}
                objectBuilder={this.generateComment}
                ref={this.commentsTable}
                settingsOverride={Models.genericDataSettings}
              />
            </Tab>
          )}

          <Tab eventKey={AnnouncementTab.Titles} title={"Titles"}>
            <TitleSelector
              publisherId={this.props.initialNode.Announcement.PublisherId}
              associatedTitles={this.state.currentTitles}
              onChange={this.titlesChanged}
            />
          </Tab>

          <Tab eventKey={AnnouncementTab.Attachments} title={"Attachments"}>
            <AnnouncementAttachmentForm
              currentAttachments={this.state.currentAttachments}
              parentAnnouncement={this.state.editingNode.Announcement}
              handleNewAttachment={this.handleNewAttachment}
              handleUpdatedAttachment={this.handleUpdatedAttachment}
              deleteAttachment={this.deleteAttachment}
            />
          </Tab>
        </Tabs>
        <Col>
          <Row className="formButtons">
            <Button
              style={{ flex: "1", marginRight: "10px" }}
              onClick={() =>
                this.props.saveRequested!(
                  this.state.editingNode,
                  this.state.currentAttachments,
                  this.state.editingNode.AllTags,
                  this.state.editingNode.SelectedTags,
                  this.state.currentTitles
                )
              }
              outline
              color="info"
            >
              Save Announcement Changes
            </Button>
            {this.props.isEditing && this.state.editingNode.Announcement.TableId > 0 && this.state.editingNode.Announcement.IsPublic && (
              <Button
                onClick={() => this.props.deleteRequested!(this.state.editingNode.Announcement)}
                style={{ flex: "1", marginRight: "15px" }}
                outline
                color="danger"
              >
                Delete Announcement
              </Button>
            )}
          </Row>
        </Col>
      </div>
    );
  }
}

export interface ITagSelectDropdownState {
  selectedTags: string[];
  newTag: string;
  allTags: string[];
  message: string;
}
export interface ITagSelectDropdownProps {
  currentAnnouncement: Models.IAnnouncementViewModel;
  tagsChanged: (allTags: string[], selectedTags: string[]) => void;
}

export class TagSelectDropdown extends React.Component<ITagSelectDropdownProps, ITagSelectDropdownState> {
  constructor(props: ITagSelectDropdownProps | Readonly<ITagSelectDropdownProps>) {
    super(props);
    this.state = {
      selectedTags: this.props.currentAnnouncement.SelectedTags,
      allTags: this.props.currentAnnouncement.AllTags,
      newTag: "",
      message: "",
    };
  }

  toggleTag = (option: string) => {
    const selectedTags = this.state.selectedTags;

    let newSelectedTags: string[] = [];
    if (selectedTags.includes(option)) {
      newSelectedTags = selectedTags.filter((item) => item !== option);
      this.setState({
        selectedTags: newSelectedTags,
      });
    } else {
      newSelectedTags = [...selectedTags, option];
      this.setState({
        selectedTags: newSelectedTags,
      });
    }

    this.props.tagsChanged(this.state.allTags, newSelectedTags);
  };

  handleNewTagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ newTag: event.target.value });
  };

  createNewTag = () => {
    const { newTag, allTags } = this.state;
    let message = "";

    if (newTag.trim() === "") {
      message = "Tag name cannot be empty.";
    } else if (allTags.includes(newTag)) {
      message = "Tag already exists.";
    } else {
      message = "Tag added successfully!";
      this.setState({
        allTags: [...allTags, newTag],
        newTag: "",
      });
    }

    let newAllTags = [...allTags, newTag];
    this.props.tagsChanged(newAllTags, this.state.selectedTags);
    setTimeout(() => {
      this.setState({ message: "" });
    }, 3000);

    this.setState({ message });
  };

  render() {
    return (
      <div>
        {this.state.allTags.length > 0 ? (
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Select Tags
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ maxHeight: "150px", overflowY: "auto" }}>
              {this.state.allTags.map((option, index) => (
                <Dropdown.Item key={index} onClick={() => this.toggleTag(option)} active={this.state.selectedTags.includes(option)}>
                  {option}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <div>No tags available</div>
        )}
        <div>
          <strong>Selected Tags: {this.state.selectedTags.length === 0 && <>None</>}</strong>
          {this.state.selectedTags.join(", ")}
        </div>
        <Label check for="tag-create">
          Tag Name
        </Label>
        <Input id="tag-create" type="text" className="mb-2" value={this.state.newTag} onChange={this.handleNewTagChange} />
        <Button onClick={this.createNewTag}>Create Tag</Button>
        {this.state.message && <span style={{ marginLeft: "10px" }}>{this.state.message}</span>}
      </div>
    );
  }
}
