import { Guid } from "guid-typescript";
import React from "react";
import { Languages } from "src/localization/Locale";
import { AppSession } from "src/models/AppSession";
import { UserFormSubmissionState, UserFormSubmissionType } from "src/models/dto/UserFormRequest";
import { Drawer, DrawerContainer } from "src/ui/foundation/Controls";
import { UserFormStateFilter } from "src/ui/foundation/Controls/UserFormStateFilter";
import { DataItem, DataRow, DataTable } from "src/ui/foundation/DataTable";
import { DashboardView } from "src/ui/foundation/Layout";
import { Action, INode, IRequest, IResponse } from "src/ui/foundation/StandaloneCogniflow";
import { AppContext } from "src/ui/state/Contextes";
import { Convert } from "src/utilities/Helpers";

import * as Models from "../../../models/dto/DashboardModels";
import * as Messages from "../../foundation/Messages";
import { PanelView } from "../PanelView";
import { BulletinForm } from "./BulletinForm";

export interface IBulletinViewProps {
  IsLoggedIn: boolean;
}
export interface IBulletinViewState {
  drawerShow: boolean;
  currentDrawerContent: JSX.Element | null;
  bulletins: [];
  panelDisabled: boolean;
  panelList: Models.ILoginLibrary[];
  selectedPublisher: number;
  selectedTitle: Models.ITitle | null;
  loading: boolean;
  name: string;
  userFormFilter: UserFormSubmissionState;
  logo: string;
}
export class BulletinView extends React.Component<IBulletinViewProps, IBulletinViewState> {
  context: AppSession;
  static contextType = AppContext;
  bulletinsTable = React.createRef<DataTable>();
  titlesTable = React.createRef<DataTable>();
  panelRef = React.createRef<PanelView>();

  constructor(props: IBulletinViewProps) {
    super(props);
    this.state = {
      drawerShow: false,
      currentDrawerContent: null,
      panelDisabled: false,
      bulletins: [],
      panelList: [],
      selectedPublisher: 0,
      selectedTitle: null,
      loading: true,
      name: "",
      logo: "",
      userFormFilter: UserFormSubmissionState.New,
    };
  }

  componentDidMount() {
    this.context.viewedViews.get(DashboardView.Bulletins)!.loading.on(this.loginInit);
  }
  componentWillUnmount() {
    this.context.viewedViews.get(DashboardView.Bulletins)!.loading.off(this.loginInit);
  }

  private reloadTitleAndDismiss = () => {
    this.titlesTable.current!.reload();
    this.setState({ currentDrawerContent: null, drawerShow: false });
  };

  publisherChanged = (newPub: Models.ILoginLibrary) => {
    this.setState({ loading: true }, () => {
      this.setState(
        {
          selectedPublisher: newPub.PublisherId,
          name: newPub.DisplayName,
          logo: newPub.LibraryIcon,
          loading: false,
          bulletins: [],
          selectedTitle: null,
        },
        () => {
          this.reloadTitleAndDismiss();
          this.bulletinsTable.current!.reload();
        }
      );
    });
  };

  loginInit = () => {
    let allowedPublisher = this.context.getManageableUserFormsPublishers(UserFormSubmissionType.Bulletin);
    this.context.viewedViews.get(DashboardView.Bulletins)!.progressLoading();
    if (allowedPublisher.length === 0) {
      return;
    } else {
      this.setState(
        {
          panelList: allowedPublisher,
          selectedPublisher: allowedPublisher[0].PublisherId,
        },
        () => {
          this.publisherChanged(allowedPublisher[0]);
        }
      );
    }
  };

  private bulletinsFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowUserForms({
        FlowRequest: request.Batches[0],
        TitleId: this.state.selectedTitle === null ? 0 : this.state.selectedTitle.TableId,
        PublisherId: this.state.selectedPublisher,
        FormType: UserFormSubmissionType.Bulletin,
        StateFilter: this.state.userFormFilter,
      });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });

  private initializeBulletins = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      let result = await this.context.flowUserForms({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
        TitleId: this.state.selectedTitle === null ? 0 : this.state.selectedTitle.TableId,
        PublisherId: this.state.selectedPublisher,
        FormType: UserFormSubmissionType.Bulletin,
        StateFilter: this.state.userFormFilter,
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });

  private saveBulletin = async (e: Models.IUserFormSubmission, titles: Models.ITitle[], att: Models.IUserFormSubmissionAttachment[]) => {
    let response = await this.context.insertOrUpdateUserForm({
      UserForm: e,
      Titles: titles.map((x) => x.TableId),
      PublisherId: this.state.selectedPublisher,
      FormType: UserFormSubmissionType.Bulletin,
      Attachments: att,
    });
    if (response.valid()) {
      Messages.Notify.success(`The item was saved successfully!`);
      this.bulletinsTable.current!.reload();
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };

  private deleteBulletin = async (e: Models.IUserFormSubmission) => {
    let result = await Messages.Dialog.confirm(`Are you sure you wish to reject this item? It will no longer appear on the Reader.`);
    if (result === "true") {
      let response = await this.context.deleteUserForm({ FormType: UserFormSubmissionType.Bulletin, Id: e.TableId });
      if (response.valid()) {
        Messages.Notify.success(`The bulletin was rejected successfully!`);
        this.bulletinsTable.current!.reload();
        this.setState({ currentDrawerContent: null, drawerShow: false });
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Deletion failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    }
  };

  private bulletinInsertRequest = async () => {
    let blankModel: Models.IUserFormViewModel = {
      TableId: 0,
      CreationDate: new Date(),
      Submission: "{}",
      DataSchema: "",
      DashboardUISchema: "",
      UserReadReceiptNeeded: false,
      UserFormDefinitionId: -1,
      PublisherId: this.state.selectedPublisher,
      AssociatedTitles: this.state.selectedTitle !== null ? [this.state.selectedTitle] : [],
      Index: -1,
      IsFirst: false,
      IsLast: false,
      LastModificationDate: new Date(),
      UserFormSubmissionAttachments: [],
      SubmissionState: UserFormSubmissionState.New,
      ApproverUserId: null,
      BreadCrumb: "",
      TableGuid: Guid.create().toString(),
      UserId: this.context.user!.TableId,
      LastModificationUserId: null,
      LastModifiedBy: null,
      CreatedBy: this.context.user!,
      ApprovedBy: null,
      ApprovalDate: new Date(),
    };
    let result = await this.context.getActiveUserFormDefinition({ FormType: UserFormSubmissionType.Bulletin, PublisherId: this.state.selectedPublisher });
    if (!result.valid()) {
      if (result.errors.length > 0) {
        Messages.Notify.error("Create failed. Server reported: " + result.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
      this.reloadTitleAndDismiss();
      return;
    }
    blankModel.DataSchema = result.data.ActiveUserFormDefinition.DataSchema;
    blankModel.DashboardUISchema = result.data.ActiveUserFormDefinition.DashboardUiSchema;
    blankModel.UserFormDefinitionId = result.data.ActiveUserFormDefinition.TableId;
    this.setState({ drawerShow: true, currentDrawerContent: <BulletinForm initialNode={blankModel} saveRequested={this.saveBulletin} /> });
  };
  private bulletinRowEditRequest = (e: INode) => {
    e.PublisherId = this.state.selectedPublisher;

    this.setState({
      drawerShow: true,
      currentDrawerContent: (
        <BulletinForm initialNode={e as Models.IUserFormViewModel} saveRequested={this.saveBulletin} deleteRequested={this.deleteBulletin} />
      ),
    });
  };
  private generateBulletins = (n: INode): JSX.Element => {
    let node = n as Models.IUserFormViewModel;
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    let dataItems = [];
    try {
      let submission = node.Submission === "" ? {} : JSON.parse(node.Submission);
      let title = submission.title;
      dataItems.push(<DataItem flexVal={1} key={1} value={title} />);
    } catch (e) {
      dataItems.push(<DataItem flexVal={1} key={1} value={"Could not parse submission."} />);
    }
    dataItems.push(<DataItem flexVal={1} key={2} value={Convert.dateToFormattedString(node.CreationDate, Languages.English)} />);
    dataItems.push(<DataItem flexVal={1} key={3} value={Convert.dateToFormattedString(node.LastModificationDate, Languages.English)} />);

    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} rowEditRequested={this.bulletinRowEditRequest} />;
  };
  private titleEditRequest = (e: INode) => {
    this.setState(
      {
        selectedTitle: e as Models.ITitle,
      },
      () => {
        this.titlesTable.current!.reRender();
        this.bulletinsTable.current!.reload();
      }
    );
  };
  private titleFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowTitles({
        FlowRequest: request.Batches[0],
        PublisherId: this.state.selectedPublisher,
        ExcludeTitles: [],
        HideInactiveTitles: false,
      });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });

  private initializeTitle = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      let result = await this.context.flowTitles({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
        PublisherId: this.state.selectedPublisher,
        ExcludeTitles: [],
        HideInactiveTitles: false,
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private generateTitle = (n: INode): JSX.Element => {
    let node = n as Models.ITitle;
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    let dataItems = [];
    dataItems.push(<DataItem flexVal={2} key={1} value={node.Name} />);
    dataItems.push(<DataItem flexVal={1} key={2} className="leftBorder" value={Models.TitleStatus[node.TitleStatus]} />);
    dataItems.push(<DataItem flexVal={2} key={3} className="leftBorder" value={Convert.dateToFormattedString(node.PublicationDate, Languages.English)} />);
    dataItems.push(<DataItem flexVal={2} key={4} className="leftBorder" value={Convert.dateToFormattedString(node.ExpirationDate, Languages.English)} />);

    return (
      <DataRow
        className={this.state.selectedTitle !== null && this.state.selectedTitle.TableId === node.TableId ? " selected" : ""}
        node={node}
        key={node.TableId}
        attributes={attrs}
        dataItems={dataItems}
        rowEditRequested={this.titleEditRequest}
      />
    );
  };

  private titleQueryExecute = (query: string) => {
    this.titlesTable.current!.reload(query);
  };

  private bulletinTitleQueryExecute = (query: string) => {
    this.bulletinsTable.current!.reload(query);
  };

  render() {
    if (!this.props.IsLoggedIn || !this.context.viewedViews.get(DashboardView.Bulletins)!.isLoaded()) {
      return "";
    }

    let settings = JSON.parse(JSON.stringify(Models.genericDataSettings));
    settings.batchSize = 25;
    settings.maxHeight = "300px";

    return (
      <div className="mainView" style={{ display: "flex" }}>
        <PanelView
          ref={this.panelRef}
          disabled={this.state.loading}
          publisherList={this.state.panelList}
          publisherChanged={this.publisherChanged}
          showAdd={false}
          selectedPublisher={this.state.panelList.find((x) => x.PublisherId === this.state.selectedPublisher)!}
        />
        <div className="bulletinsView full-height full-width">
          <DrawerContainer direction="top" className="flex-fill d-flex flex-column full-height">
            <Drawer
              onBackdropClicked={() => {
                this.setState({ drawerShow: false, currentDrawerContent: null });
              }}
              isOpen={this.state.drawerShow}
              backdrop={true}
              className="details-view full-height full-width"
            >
              {this.state.currentDrawerContent}
            </Drawer>
            <div className="bulletinsViewInner">
              <div className="section">
                <h1>Welcome to the Bulletins View</h1>
                <p> Welcome to the bulletin view. This view is used to manage the bulletins of the selected titles. </p>
              </div>
              {this.context.canManageUserForms(UserFormSubmissionType.Bulletin, this.state.selectedPublisher) && (
                <div className="section">
                  <h2>Titles</h2>
                  <p> This list the titles of the selected publisher. </p>
                  <DataTable
                    headers={["Name", "Status", "Publication Date", "Expiration Date"]}
                    headerFlexes={[2, 1, 2, 2]}
                    flowProvider={this.titleFlowProvider}
                    initializeFlowProvider={this.initializeTitle}
                    objectBuilder={this.generateTitle}
                    ref={this.titlesTable}
                    settingsOverride={settings}
                    searchQueryComitted={this.titleQueryExecute}
                  />
                </div>
              )}
              {this.context.canManageUserForms(UserFormSubmissionType.Bulletin, this.state.selectedPublisher) && (
                <div className="section">
                  <h2>Bulletins</h2>
                  <p> The list of all the bulletins of the title you select. </p>
                  <h3>Currently showing bulletins for: {this.state.selectedTitle === null ? "All titles" : this.state.selectedTitle.Name}</h3>
                  <UserFormStateFilter
                    currentSelection={this.state.userFormFilter}
                    selectedFilterChanged={(e) => this.setState({ userFormFilter: e }, () => this.bulletinsTable.current!.reload())}
                  />
                  <DataTable
                    headers={["Title", "Creation Date", "Last Modification"]}
                    headerFlexes={[1, 1, 1]}
                    rowAddRequested={this.bulletinInsertRequest}
                    flowProvider={this.bulletinsFlowProvider}
                    initializeFlowProvider={this.initializeBulletins}
                    objectBuilder={this.generateBulletins}
                    ref={this.bulletinsTable}
                    settingsOverride={settings}
                    searchQueryComitted={this.bulletinTitleQueryExecute}
                  />
                </div>
              )}
              <div className="bottomSpacer" />
            </div>
          </DrawerContainer>
        </div>
      </div>
    );
  }
}
