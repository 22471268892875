import * as React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { Languages } from 'src/localization/Locale';
import { AppSession } from 'src/models/AppSession';
import { Loading } from 'src/ui/foundation/Controls';
import { LicenceInviteForm } from 'src/ui/foundation/Controls/LicenceInviteForm';
import { DataItem, DataRow, DataTable } from 'src/ui/foundation/DataTable';
import { Action, IBatch, INode, IRequest, IResponse } from 'src/ui/foundation/StandaloneCogniflow';
import { AppContext } from 'src/ui/state/Contextes';
import { Convert } from 'src/utilities/Helpers';

import * as Models from '../../../models/dto/DashboardModels';
import * as Messages from '../../foundation/Messages';
import { FormMode, PermissionForm } from '../SystemView/PermissionForm';

enum SubscriptionTab {
  Customer,
  Product,
  Properties,
  Licences,
  Conditions,
}
export interface ISubscriptionFormProps {
  goBackButtonClicked?: () => void;
  initialNode: Models.ISubscriptionViewModel;
  publisherId: number;
  curatorMode?: boolean;
}
export interface ISubscriptionFormState {
  editingNode: Models.ISubscriptionViewModel;
  activeTab: SubscriptionTab;
  loading: boolean;
  editingCondition: number;
  editingLicence: number;

  licenceMode: boolean;
  newLicence: Models.ILicence | null;

  subManagerMode: boolean;
  newPermission: Models.IPermissionViewModel | null;
}
export class SubscriptionForm extends React.Component<ISubscriptionFormProps, ISubscriptionFormState> {
  context: AppSession;
  static contextType = AppContext;
  productTable = React.createRef<DataTable>();
  conditionTable = React.createRef<DataTable>();
  licenceTable = React.createRef<DataTable>();
  customerTable = React.createRef<DataTable>();
  constructor(props: ISubscriptionFormProps) {
    super(props);
    let defaultTab = this.props.initialNode.Customer === null ? SubscriptionTab.Customer : SubscriptionTab.Product;
    if (props.curatorMode === true) {
      defaultTab = SubscriptionTab.Licences;
    }
    this.state = {
      activeTab: defaultTab,
      editingNode: JSON.parse(JSON.stringify(props.initialNode)),
      loading: false,
      editingCondition: -1,
      editingLicence: -1,
      licenceMode: false,
      newLicence: null,
      newPermission: null,
      subManagerMode: false,
    };
  }
  componentDidMount() {
    this.setState({ loading: true }, async () => {
      // Only complete product if this is an edit.
      if (this.props.initialNode.Subscription.TableId > 0) {
        let response = await this.context.getFullSubscription({ TableId: this.props.initialNode.Subscription.TableId });
        if (response.valid()) {
          this.setState({ editingNode: response.data.Subscription }, () => {
            this.conditionTable.current!.reload();
          });
        } else {
          if (response.errors.length > 0) {
            Messages.Notify.error("Fetch failed. Server reported: " + response.errors[0].Message);
          } else {
            Messages.Notify.error("An error occurred while executing the communication");
          }
        }
      }
      this.setState({ loading: false });
    });
  }

  deleteRequested = async () => {
    let dResp = await Messages.Dialog.confirm(
      <div>
        <span>Are you absolutely sure you wish to delete this subscription? Doing so will:</span>
        <ul>
          <li>Delete ALL Licences related to this subscription.</li>
          <li>Users on this subscription will no longer have access to the titles afforded to them by this subscription.</li>
        </ul>
      </div>,
      "Delete subscription?",
      Messages.Dialog.Buttons.DeleteCancel
    );
    if (dResp === "true") {
      let response = await this.context.deleteSubscription({ Subscription: this.state.editingNode.Subscription });
      if (response.valid()) {
        Messages.Notify.success("Subscription deleted successfully!");
        if (this.props.goBackButtonClicked) {
          this.props.goBackButtonClicked();
        }
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Delete failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    }
  };
  saveRequested = async () => {
    if (this.formValid()) {
      let response = await this.context.insertOrUpdateSubscription({
        Subscription: this.state.editingNode.Subscription,
        Conditions: this.state.editingNode.Conditions,
      });
      if (response.valid()) {
        Messages.Notify.success(`The Subscription was saved successfully!`);
        this.setState({ editingNode: response.data.Subscription }, () => {
          this.licenceTable.current!.reload();
          this.productTable.current!.reload();
          this.conditionTable.current!.reload();
        });
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    }
  };

  formValid = () => {
    let node = this.state.editingNode;
    if (node.Subscription.DefinitionId <= 0 || node.ProductDef === null) {
      Messages.Notify.error("No product has been associated to this subscription.");
      return false;
    }
    if (node.Conditions.length > 0) {
      if (node.Conditions.some((x) => Convert.isEmptyOrSpaces(x.ConditionData))) {
        Messages.Notify.error("One or more conditions set on this product have empty data.");
        return false;
      }
    }
    if (node.Subscription.ContentPermissions > 3 || node.Subscription.ContentPermissions < 0) {
      Messages.Notify.error("The content permissions for this product are incorrect.");
      return false;
    }
    if (node.Subscription.OfflineAccessDurationType === Models.DurationType.Undefined) {
      Messages.Notify.error("Offline duration type must be set.");
      return false;
    }
    if (node.Subscription.OfflineAccessDurationType !== Models.DurationType.Permanent && node.Subscription.OfflineAccessDuration <= 0) {
      Messages.Notify.error("Offline duration must be set.");
      return false;
    }
    if (node.Subscription.OfflineLogins < 0) {
      Messages.Notify.error("Offline Logins invalid.");
      return false;
    }
    return true;
  };

  // #region Product
  private initializeProduct = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      if (this.state.editingNode.Customer === null) {
        reject();
        return;
      }
      let result = await this.context.flowProducts({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
        PublisherId: this.props.publisherId,
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });
  private productFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowProducts({ FlowRequest: request.Batches[0], PublisherId: this.props.publisherId });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });
  private generateProduct = (n: INode): JSX.Element => {
    let node = n as Models.IProductViewModel;
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.ProductDef.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    let dataItems = [];

    dataItems.push(<DataItem flexVal={1} className="centerText" key={1} value={node.ProductDef.MasterCode} />);
    dataItems.push(<DataItem flexVal={1} className="rightBorder leftBorder centerText" key={2} value={node.ProductDef.ProductCode} />);
    dataItems.push(<DataItem flexVal={3} className="" key={3} value={node.ProductDef.Name} />);
    let startDate = Convert.dateToFormattedString(node.ProductDef.StartDate, Languages.English);
    dataItems.push(<DataItem flexVal={2} className="rightBorder leftBorder centerText" key={4} value={startDate} />);

    let value = "";
    if (node.ProductDef.EndDate === null) {
      value = "Never";
    } else if (new Date(node.ProductDef.EndDate) < new Date()) {
      value = "Expired";
    } else {
      value = Convert.dateToFormattedString(node.ProductDef.EndDate, Languages.English);
    }

    if (value === "Expired") {
      dataItems.push(
        <DataItem flexVal={2} className="rightBorder leftBorder centerText" key={5} value={null}>
          <span style={{ color: "red" }}>{value}</span>
        </DataItem>
      );
    } else if (value === "Never") {
      dataItems.push(<DataItem flexVal={2} className="rightBorder leftBorder centerText bolded" key={5} value={value} />);
    } else {
      dataItems.push(<DataItem flexVal={2} className="rightBorder leftBorder centerText" key={5} value={value} />);
    }
    return (
      <DataRow
        className={this.state.editingNode.ProductDef !== null && node.ProductDef.TableId === this.state.editingNode.ProductDef.TableId ? " selected" : ""}
        node={node}
        key={node.Index}
        attributes={attrs}
        dataItems={dataItems}
        rowEditRequested={this.props.curatorMode === true ? undefined : this.selectProduct}
      />
    );
  };
  private selectProduct = (n: INode) => {
    let node = n as Models.IProductViewModel;
    this.setState(
      (prevState) => {
        let thing = { ...prevState };
        thing.editingNode.ProductDef = node.ProductDef;
        thing.editingNode.Subscription.DefinitionId = node.ProductDef.TableId;
        return thing;
      },
      () => {
        this.productTable.current!.reRender();
      }
    );
  };
  private productQueryExecute = (query: string) => {
    this.productTable.current!.reload(query);
  };
  // #endregion
  // #region Properties
  startDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    let parts = e.target.value.split("-");
    let dato = new Date(Date.UTC(+parts[0], +parts[1] - 1, +parts[2]));
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, Subscription: { ...prevState.editingNode.Subscription, StartDate: dato } },
    }));
  };
  endDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    let parts = e.target.value.split("-");
    let dato = new Date(Date.UTC(+parts[0], +parts[1] - 1, +parts[2]));
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, Subscription: { ...prevState.editingNode.Subscription, EndDate: dato } },
    }));
  };
  offlineDurationChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, Subscription: { ...prevState.editingNode.Subscription, OfflineAccessDuration: +e.target.value } },
    }));
  };
  offlineLoginsChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, Subscription: { ...prevState.editingNode.Subscription, OfflineLogins: +e.target.value } },
    }));
  };
  offlineDurationTypeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, Subscription: { ...prevState.editingNode.Subscription, OfflineAccessDurationType: +e.target.value } },
    }));
  };

  authSubscriptionChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, Subscription: { ...prevState.editingNode.Subscription, AuthSubscription: e.target.value } },
    }));
  };

  maxLicencesChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, Subscription: { ...prevState.editingNode.Subscription, TotalLicences: +e.target.value } },
    }));
  };

  accessAfterExpiryChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, Subscription: { ...prevState.editingNode.Subscription, AccessAfterExpiration: e.target.checked } },
    }));
  };
  neverExpiresChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Subscription: {
          ...prevState.editingNode.Subscription,
          EndDate: e.target.checked ? new Date(9998, 1, 1) : new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        },
      },
    }));
  };
  canPrintChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    if (this.state.editingNode.Subscription.ContentPermissions === 1 && e.target.checked) {
      this.setState((prevState) => ({
        editingNode: { ...prevState.editingNode, Subscription: { ...prevState.editingNode.Subscription, ContentPermissions: 3 } },
      }));
    } else if (this.state.editingNode.Subscription.ContentPermissions === 3 && !e.target.checked) {
      this.setState((prevState) => ({
        editingNode: { ...prevState.editingNode, Subscription: { ...prevState.editingNode.Subscription, ContentPermissions: 1 } },
      }));
    } else if (this.state.editingNode.Subscription.ContentPermissions === 0 && e.target.checked) {
      this.setState((prevState) => ({
        editingNode: { ...prevState.editingNode, Subscription: { ...prevState.editingNode.Subscription, ContentPermissions: 2 } },
      }));
    } else if (this.state.editingNode.Subscription.ContentPermissions === 2 && !e.target.checked) {
      this.setState((prevState) => ({
        editingNode: { ...prevState.editingNode, Subscription: { ...prevState.editingNode.Subscription, ContentPermissions: 0 } },
      }));
    }
  };
  canCopyChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    if (this.state.editingNode.Subscription.ContentPermissions === 2 && e.target.checked) {
      this.setState((prevState) => ({
        editingNode: { ...prevState.editingNode, Subscription: { ...prevState.editingNode.Subscription, ContentPermissions: 3 } },
      }));
    } else if (this.state.editingNode.Subscription.ContentPermissions === 3 && !e.target.checked) {
      this.setState((prevState) => ({
        editingNode: { ...prevState.editingNode, Subscription: { ...prevState.editingNode.Subscription, ContentPermissions: 2 } },
      }));
    } else if (this.state.editingNode.Subscription.ContentPermissions === 0 && e.target.checked) {
      this.setState((prevState) => ({
        editingNode: { ...prevState.editingNode, Subscription: { ...prevState.editingNode.Subscription, ContentPermissions: 1 } },
      }));
    } else if (this.state.editingNode.Subscription.ContentPermissions === 1 && !e.target.checked) {
      this.setState((prevState) => ({
        editingNode: { ...prevState.editingNode, Subscription: { ...prevState.editingNode.Subscription, ContentPermissions: 0 } },
      }));
    }
  };

  copyRights = () => {
    this.setState((prevState) => {
      prevState.editingNode.Subscription.AccessAfterExpiration = this.state.editingNode.ProductDef!.AccessAfterExpiration;
      prevState.editingNode.Subscription.ContentPermissions = this.state.editingNode.ProductDef!.ContentPermissions;
      prevState.editingNode.Subscription.EndDate = this.state.editingNode.ProductDef!.EndDate;
      prevState.editingNode.Subscription.OfflineAccessDuration = this.state.editingNode.ProductDef!.OfflineAccessDuration;
      prevState.editingNode.Subscription.OfflineAccessDurationType = this.state.editingNode.ProductDef!.OfflineAccessDurationType;
      prevState.editingNode.Subscription.OfflineLogins = this.state.editingNode.ProductDef!.OfflineLoginsNumber;
      prevState.editingNode.Subscription.StartDate = this.state.editingNode.ProductDef!.StartDate;
      return prevState;
    });
  };
  // #endregion
  // #region Conditions
  private initializeConditions = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>((resolve, reject) => {
      let result = this.state.editingNode.Conditions;
      if (result === null || result === undefined) {
        reject();
        return;
      }
      let request: IBatch = {
        Action: Action.insert,
        AnchorMainId: 0,
        Nodes: [],
        BatchSize: Models.genericDataSettings.batchSize,
        TargetMainId: 0,
        Query: query,
      };
      request.Nodes = result;
      request.BatchSize = 10000;
      resolve({
        nodes: Convert.indexify(request).Nodes,
        targetSpine: 0,
      });
    });
  private conditionFlowProvider = (): Promise<IResponse> =>
    new Promise<IResponse>((resolve) => {
      resolve({ Batches: [] });
    });
  generateCondition = (n: INode) => {
    let node = n as Models.IProductCondition;
    let dataItems = [];
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;

    dataItems.push(<DataItem flexVal={1} className="" key={1} value={Models.ProductConditionType[node.ConditionType]} />);
    dataItems.push(<DataItem flexVal={2} className="rightBorder leftBorder centerText" key={2} value={node.ConditionData} />);
    return (
      <DataRow
        className={this.state.editingCondition > -1 && node.Index === this.state.editingCondition ? " selected" : ""}
        node={node}
        key={node.Index}
        attributes={attrs}
        dataItems={dataItems}
        rowEditRequested={this.props.curatorMode === true ? undefined : this.conditionEdit}
      />
    );
  };
  conditionEdit = (n: INode) => {
    let node = n as Models.IProductCondition;
    this.setState({ editingCondition: node.Index }, () => {
      this.conditionTable.current!.reRender();
    });
  };
  addCondition = () => {
    let holder = this.state.editingNode.Conditions;
    let newCondition: Models.ISubscriptionCondition = {
      ConditionData: "",
      ConditionAuthId: "",
      ConditionType: Models.ProductConditionType.IpList,
      SubscriptionId: this.state.editingNode.Subscription.TableId,
      TableId: 0,
      Index: 0,
      IsFirst: false,
      IsLast: false,
    };
    holder.push(newCondition);
    this.setState(
      (prevState) => ({
        editingNode: { ...prevState.editingNode, Conditions: holder },
        editingCondition: holder.length - 1,
      }),
      () => this.conditionTable.current!.reload()
    );
  };
  conditionTypeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    let holder = this.state.editingNode.Conditions;
    holder[this.state.editingCondition].ConditionType = +e.target.value;
    this.setState(
      (prevState) => ({
        editingNode: { ...prevState.editingNode, Conditions: holder },
      }),
      () => this.conditionTable.current!.reload()
    );
  };
  conditionDataChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    let holder = this.state.editingNode.Conditions;
    holder[this.state.editingCondition].ConditionData = e.target.value;
    this.setState(
      (prevState) => ({
        editingNode: { ...prevState.editingNode, Conditions: holder },
      }),
      () => this.conditionTable.current!.reload()
    );
  };
  deleteCondition = () => {
    let holder = this.state.editingNode.Conditions;
    holder.splice(this.state.editingCondition, 1);
    this.setState(
      (prevState) => ({
        editingNode: { ...prevState.editingNode, Conditions: holder },
        editingCondition: -1,
      }),
      () => this.conditionTable.current!.reload()
    );
  };
  // #endregion
  // #region Licences
  private initializeLicences = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      if (this.state.editingNode.Subscription.TableId === -1) {
        reject();
        return;
      }
      let result = await this.context.flowSubscriptionLicences({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
        SubscriptionId: this.state.editingNode.Subscription.TableId,
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });
  private LicenceFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowSubscriptionLicences({
        FlowRequest: request.Batches[0],
        SubscriptionId: this.state.editingNode.Subscription.TableId,
      });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });
  selectLicence = (n: INode) => {
    let node = n as Models.ILicenceViewModel;
    this.setState({ editingLicence: node.Licence.TableId }, () => {
      this.licenceTable.current!.reRender();
    });
  };
  private generateLicence = (n: INode): JSX.Element => {
    let node = n as Models.ILicenceViewModel;
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.Licence.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    let dataItems = [];

    dataItems.push(<DataItem flexVal={2} key={1} className="" value={node.OwnerUsername} />);
    dataItems.push(
      <DataItem
        flexVal={1}
        key={2}
        className="leftBorder centerText"
        value={node.Licence.ProductConditionLock !== null || node.Licence.SubscriptionConditionLock !== null}
      />
    );

    return (
      <DataRow
        className={this.state.editingLicence === node.Licence.TableId ? " selected" : ""}
        node={node}
        key={node.Index}
        attributes={attrs}
        dataItems={dataItems}
        rowEditRequested={this.selectLicence}
      />
    );
  };
  inviteSubscriptionCurator = () => {
    let d1yr = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

    this.setState({
      subManagerMode: true,
      newPermission: {
        Index: -1,
        IsFirst: false,
        IsLast: false,
        Permission: {
          CreatedDate: new Date(),
          ExpirationDate: d1yr,
          GrantedByUserId: -1,
          ManageProducts: false,
          ManagePublishers: false,
          ManageSubscription: true,
          ManageSystem: false,
          ManageTitles: false,
          ManageBulletins: false,
          ManageFeedback: false,
          ManageTips: false,
          ManageOfflinePackages: false,
          PublisherId: this.state.editingNode.ProductDef!.PublisherId,
          SubscriptionId: this.state.editingNode.Subscription.TableId,
          TableId: -1,
          UserId: -1,
          ManageAccounts: false,
          ManageReporting: false,
          ManageContentReview: false,
          ManageAnnouncements: false
        },
      },
    });
  };
  private savePermission = async (e: Models.IPermissionViewModel) => {
    let response = await this.context.insertOrUpdatePermission({ Permission: e.Permission });
    if (response.valid()) {
      Messages.Notify.success(`The Permission item was saved successfully!`);
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };
  addLicence = () => {
    this.setState({
      licenceMode: true,
      newLicence: {
        ConditionLastCheck: new Date(),
        LicenceRef: "00000000-0000-0000-0000-000000000000",
        ProductConditionLock: null,
        Status: Models.LicenceStatus.Assigned,
        SubscriptionConditionLock: null,
        SubscriptionId: this.state.editingNode.Subscription.TableId,
        TableId: -1,
        UserId: -1,
      },
    });
  };
  revokeLicence = async () => {
    let dResp = await Messages.Dialog.confirm(
      "Are you sure you want to revoke this licence? The user will lose access to this subscription.",
      "Revoke Licence?",
      Messages.Dialog.Buttons.YesNo
    );
    if (dResp === "true") {
      let lic = {
        ConditionLastCheck: new Date(),
        LicenceRef: "00000000-0000-0000-0000-000000000000",
        ProductConditionLock: null,
        Status: Models.LicenceStatus.Assigned,
        SubscriptionConditionLock: null,
        SubscriptionId: this.state.editingNode.Subscription.TableId,
        TableId: this.state.editingLicence,
        UserId: -1,
      };
      let response = await this.context.deleteLicence({ Licence: lic });
      if (response.valid()) {
        Messages.Notify.success("Licence revoked successfully!");
        if (this.props.goBackButtonClicked) {
          this.props.goBackButtonClicked();
        }
        this.licenceTable.current!.reload();
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Delete failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    }
  };
  // #endregion
  // #region Customers
  private initializeCustomer = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      let result = await this.context.flowCustomers({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
        PublisherId: this.props.publisherId,
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });
  private customerFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowCustomers({ FlowRequest: request.Batches[0], PublisherId: this.props.publisherId });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });
  private selectCustomer = (n: INode) => {
    let node = n as Models.ICustomerViewModel;
    this.setState(
      (prevState) => {
        let thing = { ...prevState };
        thing.editingNode.Customer = node.Customer;
        thing.editingNode.Subscription.CustomerId = node.Customer.TableId;
        return thing;
      },
      () => {
        if (this.customerTable.current !== null) {
          this.customerTable.current.reRender();
        }
        if (this.productTable.current !== null) {
          this.productTable.current.reload();
        }
      }
    );
  };
  reloadCustomers = () => {
    this.customerTable.current!.reload();
  };
  private generateCustomer = (n: INode): JSX.Element => {
    let node = n as Models.ICustomerViewModel;
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.Customer.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    let dataItems = [];

    dataItems.push(<DataItem flexVal={2} className="centerText" key={1} value={node.Customer.CustomerNumber} />);
    dataItems.push(
      <DataItem
        flexVal={2}
        className="rightBorder leftBorder centerText"
        key={2}
        value={Convert.isEmptyOrSpaces(node.Customer.Name) ? "No name set" : node.Customer.Name}
      />
    );
    dataItems.push(
      <DataItem flexVal={3} className="rightBorder" key={3} value={Convert.isEmptyOrSpaces(node.Customer.Other) ? "No notes set" : node.Customer.Other} />
    );
    dataItems.push(<DataItem flexVal={1} className="centerText" key={4} value={node.SubscriptionCount.toString()} />);

    return (
      <DataRow
        node={node}
        key={node.Index}
        attributes={attrs}
        dataItems={dataItems}
        rowEditRequested={this.selectCustomer}
        className={this.state.editingNode.Customer !== null && node.Customer.TableId === this.state.editingNode.Customer.TableId ? " selected" : ""}
      />
    );
  };
  private customerQueryExecute = (query: string) => {
    this.customerTable.current!.reload(query);
  };
  // #endregion

  render() {
    let startDate = Convert.formatDateForForm(new Date(this.state.editingNode.Subscription.StartDate));
    let endDate = Convert.formatDateForForm(new Date(this.state.editingNode.Subscription.EndDate));
    let canPrint = this.state.editingNode.Subscription.ContentPermissions === 3 || this.state.editingNode.Subscription.ContentPermissions === 2;
    let canCopy = this.state.editingNode.Subscription.ContentPermissions === 3 || this.state.editingNode.Subscription.ContentPermissions === 1;

    if (this.state.licenceMode && this.state.newLicence !== null) {
      return <LicenceInviteForm licence={this.state.newLicence} goBackBtnClicked={() => this.setState({ newLicence: null, licenceMode: false })} />;
    }
    if (this.state.subManagerMode && this.state.newPermission !== null) {
      return (
        <PermissionForm
          formMode={FormMode.Curator}
          saveRequested={this.savePermission}
          initialNode={this.state.newPermission}
          reloadPermissions={() => {}}
          goBackBtnClicked={() => this.setState({ newPermission: null, subManagerMode: false })}
        />
      );
    }

    return (
      <Loading className="full-width full-height subscriptionForm" isLoading={this.state.loading} theme="opaque" status="Loading Subscription...">
        <div className="form-container full-width full-height">
          <h3>Subscription {this.props.curatorMode === true ? "curation" : "management"}</h3>
          <div className="subscription-tabs">
            <Tabs defaultActiveKey={this.state.activeTab} id="subscriptionTabs">
              {(this.state.editingNode.Customer === null || this.state.editingNode.Subscription.TableId <= 0) && (
                <Tab disabled={this.state.editingNode.Customer === null} eventKey={SubscriptionTab.Customer} title={"Associated Customer"}>
                  <div className="full-width full-height subscriptionProduct">
                    <Col>
                      <Row>
                        <div>
                          <h3>Selected product: {this.state.editingNode.ProductDef === null ? "None selected" : this.state.editingNode.ProductDef.Name}</h3>
                          <p>Click a customer in the list below to select it as the customer who will own this subscription.</p>
                        </div>
                        <DataTable
                          headers={["Customer Number", "Name", "Other information", "Subscription Count"]}
                          headerFlexes={[2, 2, 3, 1]}
                          flowProvider={this.customerFlowProvider}
                          initializeFlowProvider={this.initializeCustomer}
                          objectBuilder={this.generateCustomer}
                          ref={this.customerTable}
                          settingsOverride={Models.genericDataSettings}
                          searchQueryComitted={this.customerQueryExecute}
                        />
                      </Row>
                    </Col>
                  </div>
                </Tab>
              )}
              <Tab disabled={this.state.editingNode.Customer === null} eventKey={SubscriptionTab.Product} title={"Associated Product"}>
                <div className="full-width full-height subscriptionProduct">
                  <Col>
                    <Row>
                      <div>
                        <h3>Selected product: {this.state.editingNode.ProductDef === null ? "None selected" : this.state.editingNode.ProductDef.Name}</h3>
                        <p>Click a product in the list below to select it as the product that will be served to users with a Licences to this subscription.</p>
                      </div>
                      {!this.state.loading && (
                        <DataTable
                          headers={["Master Code", "Product Code", "Name", "Product Start Date", "Product End Date"]}
                          headerFlexes={[1, 1, 3, 2, 2]}
                          flowProvider={this.productFlowProvider}
                          initializeFlowProvider={this.initializeProduct}
                          objectBuilder={this.generateProduct}
                          ref={this.productTable}
                          settingsOverride={Models.genericDataSettings}
                          searchQueryComitted={this.productQueryExecute}
                        />
                      )}
                    </Row>
                  </Col>
                </div>
              </Tab>
              <Tab disabled={this.state.editingNode.ProductDef === null} eventKey={SubscriptionTab.Properties} title={"Properties"}>
                <div className="full-width full-height subscriptionProperties">
                  <Col>
                    <Row>
                      <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                        <Label for="subId">Subscription ID</Label>
                        <Input disabled type="text" name="subId" id="subId" value={this.state.editingNode.Subscription.TableId} />
                      </FormGroup>
                      <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                        <Label for="authSubscription">AuthSubscription value</Label>
                        <Input
                          disabled={this.props.curatorMode === true}
                          type="text"
                          name="authSubscription"
                          id="authSubscription"
                          value={this.state.editingNode.Subscription.AuthSubscription === null ? "" : this.state.editingNode.Subscription.AuthSubscription}
                          onChange={this.authSubscriptionChanged}
                        />
                      </FormGroup>
                      <FormGroup style={{ flex: "1" }}>
                        <Label for="maxLic">Max Licences</Label>
                        <Input
                          disabled={this.props.curatorMode === true}
                          type="number"
                          name="maxLic"
                          id="maxLic"
                          value={this.state.editingNode.Subscription.TotalLicences}
                          onChange={this.maxLicencesChanged}
                        />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                        <Label for="startDate">Starting date</Label>
                        <Input
                          disabled={this.props.curatorMode === true}
                          type="date"
                          name="startDate"
                          id="startDate"
                          value={startDate}
                          onChange={this.startDateChanged}
                        />
                      </FormGroup>
                      <FormGroup style={{ flex: "1" }}>
                        <Label for="endDate">Ending date</Label>
                        <Input
                          disabled={this.props.curatorMode === true}
                          type="date"
                          name="endDate"
                          id="endDate"
                          value={endDate}
                          onChange={this.endDateChanged}
                        />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                        <Label for="offlineDuration">Offline Access Duration</Label>
                        <Input
                          disabled={
                            this.state.editingNode.Subscription.OfflineAccessDurationType === Models.DurationType.Permanent || this.props.curatorMode === true
                          }
                          type="number"
                          name="offlineDuration"
                          id="offlineDuration"
                          value={this.state.editingNode.Subscription.OfflineAccessDuration}
                          onChange={this.offlineDurationChanged}
                        />
                      </FormGroup>
                      <FormGroup style={{ flex: "1", marginRight: "15px" }}>
                        <Label for="offlineDurationType">Duration Type</Label>
                        <Input
                          disabled={this.props.curatorMode === true}
                          type="select"
                          name="offlineDurationType"
                          id="offlineDurationType"
                          value={this.state.editingNode.Subscription.OfflineAccessDurationType}
                          onChange={this.offlineDurationTypeChanged}
                        >
                          {Object.keys(Models.DurationType)
                            .filter((key) => isNaN(Number(Models.DurationType[key as keyof typeof Models.DurationType])))
                            .map((it) => (
                              <option value={it} key={it} data-providerval={it}>
                                {Models.DurationType[it as keyof typeof Models.DurationType]}
                              </option>
                            ))}
                        </Input>
                      </FormGroup>
                      <FormGroup style={{ flex: "1" }}>
                        <Label for="offlineLoginsNumber">Offline logins</Label>
                        <Input
                          disabled={this.props.curatorMode === true}
                          type="number"
                          name="offlineLoginsNumber"
                          id="offlineLoginsNumber"
                          value={this.state.editingNode.Subscription.OfflineLogins}
                          onChange={this.offlineLoginsChanged}
                        />
                      </FormGroup>
                    </Row>
                    <Row>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="accessAfterExpiration">
                          <Input
                            disabled={this.props.curatorMode === true}
                            onChange={this.accessAfterExpiryChanged}
                            checked={this.state.editingNode.Subscription.AccessAfterExpiration}
                            type="checkbox"
                            name="accessAfterExpiration"
                            id="accessAfterExpiration"
                          />
                          Allow access after expiry
                        </Label>
                      </FormGroup>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="managePub">
                          <Input
                            disabled={this.props.curatorMode === true}
                            onChange={this.canCopyChanged}
                            checked={canCopy}
                            type="checkbox"
                            name="managePub"
                            id="managePub"
                          />
                          Can Copy
                        </Label>
                      </FormGroup>
                      <FormGroup check style={{ flex: "1" }}>
                        <Label check for="canPrint">
                          <Input
                            disabled={this.props.curatorMode === true}
                            onChange={this.canPrintChanged}
                            checked={canPrint}
                            type="checkbox"
                            name="canPrint"
                            id="canPrint"
                          />
                          Can Print
                        </Label>
                      </FormGroup>
                    </Row>
                    <Row>
                      <Button disabled={this.props.curatorMode === true} style={{ margin: "15px" }} outline color="info" onClick={this.copyRights}>
                        Copy rights from Product
                      </Button>
                    </Row>
                  </Col>
                </div>
              </Tab>
              <Tab disabled={this.state.editingNode.ProductDef === null} eventKey={SubscriptionTab.Conditions} title={"Subscription Conditions"}>
                <div className="full-width full-height subscriptionConditions">
                  <p>
                    Subscription conditions are rules that a subscription will check for themselves. If one of them is fulfilled, access to the product is
                    granted. Only one condition needs to pass to grant access. They come in the following types, all in JSON data format:
                  </p>
                  <ul>
                    <li>Ip List (a specific list of comma separated public IP adresses)</li>
                    <li>Ip Range (a comma separate list of IP ranges)</li>
                    <li>Concurrent (a concurrent limit of users, the subscription imposes N users using that subscription at a given time)</li>
                    <li>Concurrent Ip Range (combination of Ip Range AND Concurrent)</li>
                    <li>Concurrent Ip List (combination of Ip List AND Concurrent)</li>
                  </ul>
                  <div className="subscriptionConditionsTable">
                    <DataTable
                      headers={["Condition Type", "Data"]}
                      headerFlexes={[1, 2]}
                      flowProvider={this.conditionFlowProvider}
                      initializeFlowProvider={this.initializeConditions}
                      objectBuilder={this.generateCondition}
                      ref={this.conditionTable}
                      settingsOverride={Models.genericDataSettings}
                      rowAddRequested={this.props.curatorMode === true ? undefined : this.addCondition}
                    />
                  </div>
                  {this.state.editingCondition > -1 && (
                    <Col>
                      <Row>
                        <FormGroup style={{ flex: "1" }}>
                          <Label for="conditionType">Condition Type</Label>
                          <Input
                            type="select"
                            name="conditionType"
                            id="conditionType"
                            value={this.state.editingNode.Conditions[this.state.editingCondition].ConditionType}
                            onChange={this.conditionTypeChanged}
                          >
                            {Object.keys(Models.ProductConditionType)
                              .filter((key) => isNaN(Number(Models.ProductConditionType[key as keyof typeof Models.ProductConditionType])))
                              .map((it) => (
                                <option value={it} key={it} data-providerval={it}>
                                  {Models.ProductConditionType[it as keyof typeof Models.ProductConditionType]}
                                </option>
                              ))}
                          </Input>
                        </FormGroup>
                      </Row>
                      <Row>
                        <FormGroup style={{ flex: "1" }}>
                          <Label for="productDescription">Condition Data</Label>
                          <Input
                            type="textarea"
                            name="productDescription"
                            id="productDescription"
                            onChange={this.conditionDataChanged}
                            value={this.state.editingNode.Conditions[this.state.editingCondition].ConditionData}
                          />
                        </FormGroup>
                      </Row>
                      {this.props.curatorMode !== true && (
                        <Row>
                          <Button onClick={this.deleteCondition} outline color="danger">
                            Delete Condition
                          </Button>
                        </Row>
                      )}
                    </Col>
                  )}
                </div>
              </Tab>
              <Tab
                disabled={this.state.editingNode.Subscription.TableId <= 0 || this.state.editingNode.ProductDef === null}
                eventKey={SubscriptionTab.Licences}
                title={"Licences"}
              >
                <div className="full-width full-height subscriptionLicences">
                  <Col>
                    <p>These are the current Licences attached to this subscription. They do not include pending Licence invitations.</p>
                    <div className="licenceTable">
                      {!this.state.loading && (
                        <DataTable
                          headers={["User", "Using Conditional Lock"]}
                          headerFlexes={[2, 1]}
                          flowProvider={this.LicenceFlowProvider}
                          initializeFlowProvider={this.initializeLicences}
                          objectBuilder={this.generateLicence}
                          ref={this.licenceTable}
                          settingsOverride={Models.genericDataSettings}
                          rowAddRequested={this.addLicence}
                          selectedRowDelete={this.revokeLicence}
                          searchQueryComitted={() => {}}
                          canDelete={this.state.editingLicence > -1}
                        />
                      )}
                    </div>
                  </Col>
                </div>
              </Tab>
            </Tabs>
          </div>
          <FormGroup>
            <Col>
              <Row>
                {this.props.curatorMode !== true && (
                  <Button
                    onClick={this.saveRequested}
                    style={{
                      flex: "1",
                      marginRight: this.state.editingNode.Subscription.TableId > 0 && this.context.canManageSubscriptions(this.props.publisherId) ? "10px" : "",
                    }}
                    outline
                    color="primary"
                  >
                    Save Subscription
                  </Button>
                )}
                {this.props.curatorMode !== true &&
                  this.state.editingNode.Subscription.TableId > 0 &&
                  this.context.canManageSubscriptions(this.props.publisherId) && (
                    <Button onClick={this.deleteRequested} style={{ flex: "1" }} outline color="danger">
                      Delete Subscription
                    </Button>
                  )}
                {this.props.curatorMode !== true &&
                  this.state.editingNode.Subscription.TableId > 0 &&
                  this.context.canManageSubscriptions(this.props.publisherId) && (
                    <Button onClick={this.inviteSubscriptionCurator} style={{ flex: "1", marginLeft: "10px" }} outline color="info">
                      Invite Subscription Curator
                    </Button>
                  )}
                {this.props.goBackButtonClicked && (
                  <Button onClick={this.props.goBackButtonClicked} style={{ flex: "1", marginLeft: "10px" }} outline color="info">
                    Go back
                  </Button>
                )}
              </Row>
            </Col>
          </FormGroup>
        </div>
      </Loading>
    );
  }
}
