import * as React from "react";
import ReactResizeDetector from "react-resize-detector";

export enum MediaQuery {
  xs = 0,
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1200,
  xxl = 1600,
}

export enum DashboardView {
  Login,
  Home,
  Publishers,
  Subscriptions,
  Licensing,
  Products,
  Titles,
  System,
  EmailTemplates,
  Customers,
  Reporting,
  Billing,
  AccountManagement,
  Bulletins,
  Feedback,
  Tips,
  GlobalNotes,
  Announcements,
  Profile,
}

export class IFlipProps {
  children: {
    left: React.ReactNode;
    right: React.ReactNode;
  };
  className?: string;
  breakpoint: number;
  isOpen: boolean;
  panelClassName?: {
    left: string;
    right: string;
  };
}

export class IFlipState {
  compactLayout: boolean;
}

export class RightFlip extends React.Component<IFlipProps, IFlipState> {
  private flipContainer = React.createRef<HTMLDivElement>();

  constructor(props: IFlipProps | Readonly<IFlipProps>) {
    super(props);
    this.state = {
      compactLayout: false,
    };
    this.onResize = this.onResize.bind(this);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onResize(width?: number, height?: number) {
    if (width === undefined) {
      return;
    }
    this.setState({
      compactLayout: width < this.props.breakpoint,
    });
  }

  componentDidMount() {
    this.setState({
      compactLayout: this.flipContainer.current!.offsetWidth < this.props.breakpoint,
    });
  }
  render() {
    let openClass = this.props.isOpen ? "flip-open" : "";
    let layoutClass = this.state.compactLayout ? "flip-one-column" : "";
    let panelClassNames = this.props.panelClassName || { left: "", right: "" };
    return (
      <div ref={this.flipContainer} className={`flip flip-right ${this.props.className ? this.props.className : ""} ${openClass} ${layoutClass}`}>
        <ReactResizeDetector handleWidth onResize={this.onResize} />
        <div className={`flip-panel flip-panel-left ${panelClassNames.left}`}>{this.props.children.left}</div>
        <div className={`flip-panel flip-panel-right ${panelClassNames.right}`}>{this.props.children.right}</div>
      </div>
    );
  }
}
