import * as React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { Languages } from 'src/localization/Locale';
import { AppSession } from 'src/models/AppSession';
import { Drawer, DrawerContainer, Loading } from 'src/ui/foundation/Controls';
import { DataItem, DataRow, DataTable } from 'src/ui/foundation/DataTable';
import { Action, IBatch, INode, IResponse } from 'src/ui/foundation/StandaloneCogniflow';
import { AppContext } from 'src/ui/state/Contextes';
import { Convert } from 'src/utilities/Helpers';

import * as Models from '../../../models/dto/DashboardModels';
import * as Messages from '../../foundation/Messages';
import { PermissionForm } from '../SystemView/PermissionForm';

export interface IAccountFormProps {
  initialNode: Models.IUserViewModel;
  reloadUsers?: () => void;
  closeDrawer: () => void;
}
export interface IAccountFormState {
  editingNode: Models.IUserViewModel;
  resetOverrideEmail: string;
  activeTab: AccountTab;
  loading: boolean;
  loginNameChanged: boolean;
  currentPub: Models.ILoginLibrary | null;
  returnUrl: string;
  drawerShow: boolean;
  currentDrawerContent: JSX.Element | null;
}
enum AccountTab {
  Properties,
  Licences,
  Permissions,
  AuthInfo,
}
export class AccountForm extends React.Component<IAccountFormProps, IAccountFormState> {
  context: AppSession;
  static contextType = AppContext;
  userLicensesTable = React.createRef<DataTable>();
  permissionTable = React.createRef<DataTable>();
  constructor(props: IAccountFormProps) {
    super(props);
    this.state = {
      activeTab: AccountTab.Properties,
      editingNode: this.props.initialNode,
      loading: true,
      resetOverrideEmail: "",
      loginNameChanged: false,
      currentPub: null,
      returnUrl: "",
      drawerShow: false,
      currentDrawerContent: null,
    };
  }
  componentDidMount = async () => {
    let response = await this.context.getFullAccount({ UserId: this.state.editingNode.User.TableId });
    if (response.valid()) {
      this.setState({ editingNode: response.data.UserModel, loading: false, currentPub: this.context.loginLibraries.rows()[0] });
      // Reload form tables if necessary
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Fetch failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
      this.props.closeDrawer();
    }
  };
  private initializeUserLicenses = (): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>((resolve) => {
      let staticBatch: IBatch = { Action: Action.insert, AnchorMainId: 0, BatchSize: 10000, Nodes: this.state.editingNode.Licenses, TargetMainId: 0 };
      resolve({
        nodes: Convert.indexify(staticBatch).Nodes,
        targetSpine: 0,
      });
    });
  private userLicenseFlowProvider = (): Promise<IResponse> =>
    new Promise<IResponse>((resolve, reject) => {
      reject();
    });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  generateUserLicense = (n: INode) => {
    let node = n as Models.IUserLicenseViewModel;
    let dataItems = [];
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.Subscription.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    dataItems.push(<DataItem flexVal={1} key={1} className="centerText" value={node.License.TableId.toString()} />);
    dataItems.push(<DataItem flexVal={1} key={2} className="centerText" value={node.Subscription.TableId.toString()} />);
    dataItems.push(<DataItem flexVal={2} key={3} className="centerText" value={node.Product.Name} />);

    dataItems.push(
      <DataItem
        flexVal={1}
        key={4}
        className="rightBorder leftBorder centerText"
        value={Convert.dateToFormattedString(node.Subscription.StartDate, Languages.English)}
      />
    );
    if (new Date(node.Subscription.EndDate) < new Date()) {
      dataItems.push(
        <DataItem flexVal={1} className="centerText" key={5} value={null}>
          <span style={{ color: "red" }}>{Convert.dateToFormattedString(node.Subscription.EndDate, Languages.English)}</span>
        </DataItem>
      );
    } else {
      dataItems.push(
        <DataItem flexVal={1} key={5} className="centerText" value={Convert.dateToFormattedString(node.Subscription.EndDate, Languages.English)} />
      );
    }
    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} rowEditRequested={() => {}} />;
  };

  private generatePermission = (n: INode): JSX.Element => {
    let node = n as Models.IPermissionViewModel;
    node.User = this.state.editingNode.User;
    if (this.state.editingNode.AuthUser !== null) node.AuthUser = this.state.editingNode.AuthUser;
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.Permission.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    let dataItems = [];
    let value = "";
    let permissions = "";
    if (node.Permission.ExpirationDate === null) {
      value = "Never";
    } else if (new Date(node.Permission.ExpirationDate) < new Date()) {
      value = "Expired";
    } else {
      value = Convert.dateToFormattedString(node.Permission.ExpirationDate, Languages.English);
    }
    if (value === "Expired") {
      dataItems.push(
        <DataItem flexVal={2} className="centerText" key={3} value={null}>
          <span style={{ color: "red" }}>{value}</span>
        </DataItem>
      );
    } else if (value === "Never") {
      dataItems.push(<DataItem flexVal={2} className="centerText bolded" key={3} value={value} />);
    } else {
      dataItems.push(<DataItem flexVal={2} className="centerText" key={3} value={value} />);
    }
    if (node.Permission.PublisherId === null) {
      dataItems.push(<DataItem flexVal={2} className="centerText bolded" key={4} value={"All"} />);
    } else {
      dataItems.push(<DataItem flexVal={2} key={4} value={node.Publisher!.Name} />);
    }
    if (node.Permission.ManageSystem) {
      permissions += "Manage System";
    }
    if (node.Permission.ManageAccounts) {
      if (permissions !== "") {
        permissions += ", ";
      }
      permissions += "Manage Accounts";
    }
    if (node.Permission.ManageBulletins) {
      if (permissions !== "") {
        permissions += ", ";
      }
      permissions += "Manage Bulletins";
    }
    if (node.Permission.ManageFeedback) {
      if (permissions !== "") {
        permissions += ", ";
      }
      permissions += "Manage Feedback";
    }
    if (node.Permission.ManageOfflinePackages) {
      if (permissions !== "") {
        permissions += ", ";
      }
      permissions += "Manage Offline Packages";
    }
    if (node.Permission.ManageProducts) {
      if (permissions !== "") {
        permissions += ", ";
      }
      permissions += "Manage Products";
    }
    if (node.Permission.ManagePublishers) {
      if (permissions !== "") {
        permissions += ", ";
      }
      permissions += "Manage Publishers";
    }
    if (node.Permission.ManageReporting) {
      if (permissions !== "") {
        permissions += ", ";
      }
      permissions += "Manage Reporting";
    }
    if (node.Permission.ManageSubscription) {
      if (permissions !== "") {
        permissions += ", ";
      }
      permissions += "Manage Subscription";
    }
    if (node.Permission.ManageTips) {
      if (permissions !== "") {
        permissions += ", ";
      }
      permissions += "Manage Tips";
    }
    if (node.Permission.ManageTitles) {
      if (permissions !== "") {
        permissions += ", ";
      }
      permissions += "Manage Titles";
    }
    if (node.Permission.ManageContentReview) {
      if (permissions !== "") {
        permissions += ", ";
      }
      permissions += "Manage Content Review";
    }
    dataItems.push(<DataItem className="centerText" flexVal={8} key={4} value={permissions} />);
    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} rowEditRequested={this.permissionRowEditRequest} />;
  };

  private permissionRowEditRequest = (e: INode) => {
    this.setState({
      drawerShow: true,
      currentDrawerContent: (
        <PermissionForm
          initialNode={e as Models.IPermissionViewModel}
          saveRequested={this.savePermission}
          deleteRequested={this.deletePermission}
          reloadPermissions={this.reloadPermissionsAndDismiss}
        />
      ),
    });
  };
  private permissionInsertRequest = () => {
    let d1yr = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    let blankModel: Models.IPermissionViewModel = {
      User: this.state.editingNode.User,
      AuthUser: this.state.editingNode.AuthUser == null ? undefined : this.state.editingNode.AuthUser,
      Permission: {
        CreatedDate: new Date(Date.now()),
        ExpirationDate: d1yr,
        GrantedByUserId: -1,
        ManageProducts: false,
        ManagePublishers: false,
        ManageSubscription: false,
        ManageSystem: false,
        ManageTitles: false,
        ManageAccounts: false,
        ManageBulletins: false,
        ManageFeedback: false,
        ManageTips: false,
        ManageOfflinePackages: false,
        PublisherId: null,
        SubscriptionId: null,
        TableId: -1,
        UserId: -1,
        ManageReporting: false,
        ManageContentReview: false,
        ManageAnnouncements: false
      },
      Index: -1,
      IsFirst: false,
      IsLast: false,
    };

    this.setState({
      drawerShow: true,
      currentDrawerContent: (
        <PermissionForm initialNode={blankModel} saveRequested={this.savePermission} reloadPermissions={this.reloadPermissionsAndDismiss} />
      ),
    });
  };
  private reloadPermissionsAndDismiss = () => {
    this.permissionTable.current!.reload();
    this.setState({ currentDrawerContent: null, drawerShow: false });
    this.props.closeDrawer();
    // if (this.props.reloadUsers) this.props.reloadUsers();
  };
  private savePermission = async (e: Models.IPermissionViewModel) => {
    e.Permission.UserId = this.state.editingNode.User.TableId;
    let response = await this.context.insertOrUpdatePermission({ Permission: e.Permission });
    if (response.valid()) {
      Messages.Notify.success(`The Permission item was saved successfully!`);
      this.reloadPermissionsAndDismiss();
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };
  private deletePermission = async (e: Models.IPermissionViewModel) => {
    let result = await Messages.Dialog.confirm(`Are you sure you wish to delete this Permission? The user will lose all rights associated with it.`);
    if (result === "true") {
      let response = await this.context.deletePermission({ Permission: e.Permission });
      if (response.valid()) {
        Messages.Notify.success(`The Permission item was deleted successfully!`);
        this.reloadPermissionsAndDismiss();
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Deletion failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    }
  };
  private initializePermission = (): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>((resolve) => {
      let staticBatch: IBatch = { Action: Action.insert, AnchorMainId: 0, BatchSize: 10000, Nodes: this.state.editingNode.Permissions, TargetMainId: 0 };
      resolve({
        nodes: Convert.indexify(staticBatch).Nodes,
        targetSpine: 0,
      });
    });
  private permissionFlowProvider = (): Promise<IResponse> =>
    new Promise<IResponse>((resolve, reject) => {
      reject();
    });
  private resetPassword = async () => {
    if (!Convert.isEmptyOrSpaces(this.state.resetOverrideEmail) && !this.validateEmail(this.state.resetOverrideEmail)) {
      Messages.Notify.error("An override email was set, but it isn't a valid email format.");
      return;
    }
    let emailToUse = Convert.isEmptyOrSpaces(this.state.resetOverrideEmail) ? this.state.editingNode.User.Email : this.state.resetOverrideEmail;
    let response = await this.context.resetPassword({ PublisherId: this.state.currentPub!.PublisherId, UserEmail: emailToUse });
    if (response.valid()) {
      Messages.Notify.success(`The password reset operation was executed successfully!`);
      this.setState({ returnUrl: response.data.ReturnUrl });
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };
  validateEmail = (email: string) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  loginNameChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState({ loginNameChanged: true });
    this.setState((prevState) => {
      prevState.editingNode.User.LoginName = arg.target.value;
      prevState.editingNode.User.Email = arg.target.value;
      return prevState;
    });
  };
  changeUserLoginName = async () => {
    if (this.validateEmail(this.state.editingNode.User.LoginName) && this.validateEmail(this.state.editingNode.User.Email)) {
      let response = await this.context.changeUserLoginName({ User: this.state.editingNode.User });
      if (response.valid()) {
        Messages.Notify.success(`The user's login name was changed successfully!`);
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    } else {
      Messages.Notify.error("The new login name must be an email.");
    }
  };
  deleteUserAccount = async () => {
    let result = await Messages.Dialog.confirm(
      <div>
        <span>Are you absolutely sure you wish to delete this user? Doing so will:</span>
        <ul>
          <li>Delete ALL their licenses in the system.</li>
          <li>Remove all of their user content (annotations, favourites, history)</li>
          <li>The user form submissions will be preserved, but the trace of their relation to this user will be lost.</li>
          <li>Erase any trace of them within the proLibro system.</li>
        </ul>
      </div>,
      "Delete user?",
      Messages.Dialog.Buttons.DeleteCancel
    );
    if (result === "true") {
      let response = await this.context.deleteUserAccount({ UserId: this.state.editingNode.User.TableId });
      if (response.valid()) {
        Messages.Notify.success(`The user was deleted successfully!`);
        if (this.props.reloadUsers) {
          this.props.reloadUsers();
        }
        this.props.closeDrawer();
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    }
  };

  publisherChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ currentPub: this.context.loginLibraries.rows().find((x) => x.PublisherId === +e.target.value)! });
  };
  render() {
    return (
      <Loading className="full-width full-height" isLoading={this.state.loading} theme="opaque" status="Loading User...">
        {!this.state.loading && (
          <div className="form-container full-width full-height">
            <DrawerContainer direction="top" className="flex-fill d-flex flex-column full-height">
              <Drawer
                onBackdropClicked={() => {
                  this.setState({ drawerShow: false, currentDrawerContent: null });
                }}
                isOpen={this.state.drawerShow}
                backdrop={true}
                className="details-view"
              >
                {this.state.currentDrawerContent}
              </Drawer>
              <h3>User management: {this.state.editingNode.User.DisplayName}</h3>
              <div className="account-tabs">
                <Tabs defaultActiveKey={this.state.activeTab} id="accountTabs">
                  <Tab eventKey={AccountTab.Properties} title={"Properties"}>
                    <div className="full-width full-height accountProperties">
                      <Col>
                        <Row>
                          <FormGroup style={{ flex: "1" }}>
                            <Label for="userId">User Id</Label>
                            <Input disabled value={this.state.editingNode.User.TableId} type="text" name="userId" id="userId" placeholder="User Id" />
                          </FormGroup>
                          <FormGroup style={{ flex: "1" }}>
                            <Label for="loginName">Login Name (used to login to reader)</Label>
                            <Input
                              disabled={this.state.editingNode.AuthUser !== null}
                              onChange={this.loginNameChanged}
                              value={this.state.editingNode.User.LoginName}
                              type="text"
                              name="userId"
                              id="userId"
                              placeholder="Login name"
                            />
                          </FormGroup>
                          <FormGroup style={{ flex: "1" }}>
                            <Label for="displayName">Display Name</Label>
                            <Input
                              disabled
                              value={this.state.editingNode.User.DisplayName}
                              type="text"
                              name="displayName"
                              id="displayName"
                              placeholder="Display name"
                            />
                          </FormGroup>
                        </Row>
                        <Row>
                          <FormGroup style={{ flex: "1" }}>
                            <Label for="firstName">First Name</Label>
                            <Input
                              disabled
                              value={this.state.editingNode.User.FirstName === null ? "" : this.state.editingNode.User.FirstName}
                              type="text"
                              name="firstName"
                              id="firstName"
                              placeholder="First Name"
                            />
                          </FormGroup>
                          <FormGroup style={{ flex: "1" }}>
                            <Label for="lastName">Last Name</Label>
                            <Input
                              disabled
                              value={this.state.editingNode.User.LastName === null ? "" : this.state.editingNode.User.LastName}
                              type="text"
                              name="lastName"
                              id="lastName"
                              placeholder="Last Name"
                            />
                          </FormGroup>
                        </Row>
                        <Row>
                          <FormGroup style={{ flex: "1" }}>
                            <Label for="address">Address</Label>
                            <Input disabled type="text" name="address" value={this.state.editingNode.User.SocialAddr} placeholder={"Address"} />
                          </FormGroup>
                          <FormGroup style={{ flex: "1" }}>
                            <Label for="addressl2">Address line 2</Label>
                            <Input disabled type="text" name="addressl2" value={this.state.editingNode.User.SocialAddr2} placeholder={"Address Line 2"} />
                          </FormGroup>
                        </Row>
                        <Row>
                          <FormGroup style={{ flex: "1" }}>
                            <Label for="phoneNumber">Phone Number</Label>
                            <Input disabled type="text" name="phoneNumber" value={this.state.editingNode.User.PhoneNumber} placeholder={"Phone"} />
                          </FormGroup>
                          <FormGroup>
                            <Label for="language">Preferred language</Label>
                            <Input disabled type="select" name="language" value={this.state.editingNode.User.LanguageType} placeholder={"Language"}>
                              {Object.keys(Models.LanguageCode)
                                .filter((key) => isNaN(Number(Models.LanguageCode[key as keyof typeof Models.LanguageCode])))
                                .map((it) => (
                                  <option value={it} key={it} data-providerval={it}>
                                    {+it === Models.LanguageCode.Undefined
                                      ? "Preferred Language*"
                                      : Models.LanguageCode[it as keyof typeof Models.LanguageCode]}
                                  </option>
                                ))}
                            </Input>
                          </FormGroup>
                          <FormGroup style={{ flex: "1" }}>
                            <Label for="industry">Industry</Label>
                            <Input disabled type="select" name="industry" value={this.state.editingNode.User.Industry} placeholder={"Industry"}>
                              {Object.keys(Models.IndustryType)
                                .filter((key) => isNaN(Number(Models.IndustryType[key as keyof typeof Models.IndustryType])))
                                .map((it) => (
                                  <option value={it} key={it} data-providerval={it}>
                                    {+it === Models.IndustryType.Undefined ? "Industry*" : Models.IndustryType[it as keyof typeof Models.IndustryType]}
                                  </option>
                                ))}
                            </Input>
                          </FormGroup>
                        </Row>
                        <Row>
                          <FormGroup style={{ flex: "1" }}>
                            <Label for="occupation">Occupation</Label>
                            <Input disabled type="text" name="occupation" value={this.state.editingNode.User.Occupation} placeholder={"Occupation"} />
                          </FormGroup>
                          <FormGroup style={{ flex: "1" }}>
                            <Label for="email">Email</Label>
                            <Input disabled type="text" name="email" value={this.state.editingNode.User.Email} placeholder={"Email"} />
                          </FormGroup>
                        </Row>
                      </Col>
                      {this.state.loginNameChanged && (
                        <Button style={{ margin: "15px" }} outline color="info" onClick={this.changeUserLoginName}>
                          Save login name changed
                        </Button>
                      )}
                      <Button color="danger" style={{ margin: "15px" }} outline onClick={this.deleteUserAccount}>
                        Delete User
                      </Button>
                      {this.state.editingNode.AuthUser === null && (
                        <div>
                          <h2>Reset password</h2>
                          <p>
                            Optionally Override email for password reset link. Only set this if you are doing support. It will stop a user&apos;s access to
                            their account without them receiving a reset email. If blank, Dashboard will send the email to this user&apos;s email address. If
                            set the email will go to the defined address. Can be used to temporarily take control of an account without knowing the user&apos;s
                            password directly...{" "}
                            <b>
                              Note: If you don&apos;t have an email client setup, the reset link will be displayed in this window for you to forward to the
                              user.
                            </b>
                          </p>
                          <Col>
                            <Row>
                              <FormGroup style={{ flex: "1" }}>
                                <Label for="email">Override email</Label>
                                <Input
                                  onChange={(e) => this.setState({ resetOverrideEmail: e.target.value })}
                                  type="text"
                                  name="email"
                                  value={this.state.resetOverrideEmail}
                                  placeholder={"Email override"}
                                />
                              </FormGroup>
                            </Row>
                            <p>Set a publisher to brand the reset email to.</p>
                            <Row>
                              <FormGroup style={{ flex: "1" }}>
                                {this.context.loginLibraries.length > 1 && <Label for="publisher">Publisher email template to use:</Label>}
                                {this.context.loginLibraries.length > 1 && (
                                  <Input onChange={this.publisherChanged} className={"pubSelect"} type="select" name="publisher" id="publisher">
                                    {this.context.loginLibraries.rows().map((it) => (
                                      <option value={it.PublisherId} key={it.PublisherId} data-providerval={it.PublisherId}>
                                        {it.DisplayName}
                                      </option>
                                    ))}
                                  </Input>
                                )}
                              </FormGroup>
                            </Row>
                          </Col>
                          {!Convert.isEmptyOrSpaces(this.state.returnUrl) && (
                            <p style={{ userSelect: "text" }}>
                              The link for resetting the password is:{" "}
                              <a style={{ userSelect: "text" }} rel="noreferrer" target="_blank" href={this.state.returnUrl}>
                                {this.state.returnUrl}
                              </a>
                              . Give this to your user or reset it yourself.
                            </p>
                          )}
                          <Button style={{ marginLeft: "15px" }} outline color="info" onClick={this.resetPassword}>
                            Reset user&apos;s password
                          </Button>
                        </div>
                      )}
                    </div>
                  </Tab>
                  <Tab eventKey={AccountTab.Licences} title={"Licences"}>
                    <div className="full-width full-height accountLicences">
                      {this.state.editingNode.Licenses.length === 0 && <span>This user does not have any licenses.</span>}
                      {this.state.editingNode.Licenses.length > 0 && (
                        <div>
                          <p>
                            These are the products and date ranges the selected user has license to. There may be duplicates if the user has multiple licenses
                            to different subscriptions to the same product.
                          </p>
                          <DataTable
                            headers={["License ID", "Subscription ID", "Product name", "Start Date", "End Date"]}
                            headerFlexes={[1, 1, 2, 1, 1]}
                            flowProvider={this.userLicenseFlowProvider}
                            initializeFlowProvider={this.initializeUserLicenses}
                            objectBuilder={this.generateUserLicense}
                            ref={this.userLicensesTable}
                            settingsOverride={Models.genericDataSettings}
                          />
                        </div>
                      )}
                    </div>
                  </Tab>
                  <Tab eventKey={AccountTab.Permissions} title={"Permissions"}>
                    <div className="full-width full-height accountPermissions">
                      {this.state.editingNode.Permissions.length === 0 && <span>This user does not have any permissions.</span>}
                      {this.state.editingNode.Permissions.length > 0 && (
                        <div>
                          <p>These are the permissions that have been extended to this user within the system.</p>
                          <p>Click on a row to edit the permission, or click the + to add a new permission.</p>
                          <DataTable
                            headers={[
                              "Expires (UTC)",
                              "Publisher",
                              "Permission Allowance",
                            ]}
                            headerFlexes={[2,2,8]}
                            rowAddRequested={this.permissionInsertRequest}
                            flowProvider={this.permissionFlowProvider}
                            initializeFlowProvider={this.initializePermission}
                            objectBuilder={this.generatePermission}
                            ref={this.permissionTable}
                          />
                        </div>
                      )}
                    </div>
                  </Tab>
                  {this.state.editingNode.AuthUser !== null && (
                    <Tab eventKey={AccountTab.AuthInfo} title={"Auth Info"}>
                      <div className="full-width full-height accountAuthInfo">
                        <p>
                          This user is an auth user for the auth provider <b>{Models.AuthProviderType[this.state.editingNode.AuthUser.AuthProvider]}</b>
                        </p>
                        <p>They have the following auth information:</p>
                        <ul>
                          <li>
                            The user ID attributed to them by the auth provider is: <b>{this.state.editingNode.AuthUser.AuthUserId}</b>
                          </li>
                          <li>
                            The username attributed to them by the auth provider is: <b>{this.state.editingNode.AuthUser.AuthUserName}</b>
                          </li>
                          <li>
                            The customer number attributed to them by the auth provider is: <b>{this.state.editingNode.AuthUser.CustomerNumber}</b>
                          </li>
                          <li>
                            Their local Dashboard user ID is: <b>{this.state.editingNode.AuthUser.UserId}</b>
                          </li>
                        </ul>
                      </div>
                    </Tab>
                  )}
                </Tabs>
              </div>
            </DrawerContainer>
          </div>
        )}
      </Loading>
    );
  }
}
