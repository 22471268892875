import "@mdxeditor/editor/style.css";

import React, { useEffect, useRef, useState } from "react";

import {
  AdmonitionDirectiveDescriptor,
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CodeToggle,
  CreateLink,
  diffSourcePlugin,
  DiffSourceToggleWrapper,
  directivesPlugin,
  headingsPlugin,
  imagePlugin,
  InsertAdmonition,
  InsertImage,
  InsertTable,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  ListsToggle,
  markdownShortcutPlugin,
  MDXEditor,
  MDXEditorMethods,
  quotePlugin,
  Separator,
  tablePlugin,
  toolbarPlugin,
  UndoRedo,
} from "@mdxeditor/editor";

export interface IProLibroMarkdownProps {
  value: string;
  onChange: ((value: string) => void) | undefined;
  linkClicked?: (href: string) => void;
  readonly?: boolean;
}

export const ProLibroMarkdown = (props: IProLibroMarkdownProps) => {
  const [shouldUseBasicTools, setShouldUseBasicTools] = useState(true);

  const divRef = useRef<HTMLDivElement>(null);
  const markDownRef = React.useRef<MDXEditorMethods>(null);
  const doCheck = () => {
    if (divRef.current?.offsetWidth && divRef.current?.offsetWidth <= 470) {
      // If media query matches
      setShouldUseBasicTools(true);
    } else {
      setShouldUseBasicTools(false);
    }
  };
  useEffect(() => {
    markDownRef.current?.setMarkdown(props.value);
    doCheck();
    setInterval(() => {
      doCheck();
    }, 200);
  });

  const markdownLinkClicked = (e: React.MouseEvent) => {
    if ((e.target as HTMLElement).tagName === "A" || (e.target as HTMLElement).parentElement?.tagName === "A") {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <div ref={divRef} className="markdownBlockContainer" onClick={(e) => void markdownLinkClicked(e)}>
      <MDXEditor
        ref={markDownRef}
        onChange={props.onChange}
        markdown={props.value}
        contentEditableClassName="editableMarkdownContent result"
        readOnly={props.readonly}
        plugins={[
          diffSourcePlugin(),
          directivesPlugin({ directiveDescriptors: [AdmonitionDirectiveDescriptor] }),
          linkPlugin(),
          linkDialogPlugin(),
          listsPlugin(),
          headingsPlugin(),
          quotePlugin(),
          tablePlugin(),
          markdownShortcutPlugin(),
          imagePlugin(),
          toolbarPlugin({
            toolbarContents: () =>
              !props.readonly &&
              (shouldUseBasicTools ? (
                <React.Fragment>
                  <UndoRedo />
                  <Separator />
                  <BoldItalicUnderlineToggles />
                  <Separator />
                  <CreateLink />
                  <Separator />
                  <ListsToggle />
                </React.Fragment>
              ) : (
                <DiffSourceToggleWrapper>
                  <UndoRedo />
                  <Separator />
                  <BoldItalicUnderlineToggles />
                  <BlockTypeSelect />
                  <Separator />
                  <CodeToggle />
                  <CreateLink />
                  <Separator />
                  <InsertImage />
                  <InsertTable />
                  <Separator />
                  <ListsToggle />
                  <Separator />
                  <InsertAdmonition />
                </DiffSourceToggleWrapper>
              )),
          }),
        ]}
      />
    </div>
  );
};
