import * as React from 'react';
import { Languages } from 'src/localization/Locale';
import { AppSession } from 'src/models/AppSession';
import * as Models from 'src/models/dto/DashboardModels';
import { Drawer, DrawerContainer } from 'src/ui/foundation/Controls';
import { DataItem, DataRow, DataTable } from 'src/ui/foundation/DataTable';
import { DashboardView } from 'src/ui/foundation/Layout';
import { Action, INode, IRequest, IResponse } from 'src/ui/foundation/StandaloneCogniflow';
import { AppContext } from 'src/ui/state/Contextes';
import { Convert } from 'src/utilities/Helpers';

import * as Messages from '../../foundation/Messages';
import { PanelView } from '../PanelView';
import { AnnouncementForm } from './AnnouncementForm';

export interface IAnnouncementViewProps {
  IsLoggedIn: boolean;
}

export interface IAnnouncementViewState {
  panelList: Models.ILoginLibrary[];
  selectedPublisher: number;
  name: string;
  logo: any;
  drawerShow: boolean;
  currentDrawerContent: JSX.Element | null;
  loading: boolean;
  announcements: [];
  allowedPubs: Models.ILoginLibrary[];
  currentPub: Models.ILoginLibrary | null;
  panelDisabled: boolean;
  selectedTitle: Models.ITitle | null;
}

export class AnnouncementView extends React.Component<
  IAnnouncementViewProps,
  IAnnouncementViewState
> {
  context: AppSession;
  static contextType = AppContext;
  panelRef = React.createRef<PanelView>();
  announcementsTable = React.createRef<DataTable>();
  titlesTable = React.createRef<DataTable>();
   

  constructor(props: IAnnouncementViewProps) {
    super(props);
    this.state = {
      drawerShow: false,
      currentDrawerContent: null,
      panelList: [],
      announcements: [],
      selectedPublisher: 0,
      name: "",
      logo: "",
      loading: true,
      allowedPubs: [],
      currentPub: null,
      panelDisabled: false,
      selectedTitle: null
    };
  }

  componentDidMount() {
    this.context.viewedViews
      .get(DashboardView.Announcements)!
      .loading.on(this.loginInit);
  }
  componentWillUnmount() {
    this.context.viewedViews
      .get(DashboardView.Announcements)!
      .loading.off(this.loginInit);
  }

  loginInit = () => {
    let allowedPubs = this.context.getManageableAnnouncements();
    if (allowedPubs.length > 0) {
      this.setState({ allowedPubs: allowedPubs, currentPub: allowedPubs[0], selectedPublisher: allowedPubs[0].PublisherId, });
      this.context.viewedViews
        .get(DashboardView.Announcements)!
        .progressLoading();
    }
  };

  private reloadTitleAndDismiss = () => {
    this.titlesTable.current!.reload();
    this.setState({ currentDrawerContent: null, drawerShow: false });
  };

  publisherChanged = (e: Models.ILoginLibrary) => {
    this.setState(
      {
        selectedPublisher: e.PublisherId,
        currentPub: this.state.allowedPubs.find(
          (x) => x.PublisherId === e.PublisherId
        )!
      },
      () => {
        this.reloadTitleAndDismiss();
        this.announcementsTable.current!.reload();
      }
    );
  };

  private announcementFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowAnnouncements({
        FlowRequest: request.Batches[0],
        TitleId: this.state.selectedTitle === null ? 0 : this.state.selectedTitle.TableId,
        PublisherId: this.state.currentPub!.PublisherId
      });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });

  private initializeAnnouncements = (
    anchor?: number,
    query?: string
  ): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(
      async (resolve, reject) => {
        let result = await this.context.flowAnnouncements({
          FlowRequest: {
            Action: Action.insert,
            AnchorMainId: 0,
            Nodes: [],
            BatchSize: Models.genericDataSettings.batchSize,
            TargetMainId: 0,
            Query: query
          },
          TitleId: this.state.selectedTitle === null ? 0 : this.state.selectedTitle.TableId,
          PublisherId: this.state.currentPub!.PublisherId
        });
        if (result.valid()) {
          resolve({
            nodes: Convert.indexify(result.data.FlowResponse).Nodes,
            targetSpine: 0
          });
        } else {
          reject();
        }
      }
    );

  private saveAnnouncement = async (
    e: Models.IAnnouncementViewModel,
    att: Models.IAnnouncementAttachment[],
    allTags: string[],
    selectedTags: string[],
    titles: Models.ITitle[]
  ) => {
    e.Announcement.PublisherId = this.state.currentPub!.PublisherId;
    let response = await this.context.insertOrUpdateAnnouncement({
      Announcement: e.Announcement,
      Attachments: att,
      AllTags: allTags,
      SelectedTags: selectedTags,
      Titles: titles.map((x) => x.TableId),
    });
    if (response.valid()) {
      Messages.Notify.success(`The item was saved successfully!`);
      this.announcementsTable.current!.reload();
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error(
          "Save failed. Server reported: " + response.errors[0].Message
        );
      } else {
        Messages.Notify.error(
          "An error occurred while executing the communication"
        );
      }
    }
  };

  private deleteAnnouncement = async (e: Models.IAnnouncement) => {
      let response = await this.context.deleteAnnouncement({
        TableId: e.TableId
      });
      if (response.valid()) {
        Messages.Notify.success(`The announcement was deleted successfully!`);
        this.announcementsTable.current!.reload();
        this.setState({ currentDrawerContent: null, drawerShow: false });
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error(
            "Deletion failed. Server reported: " + response.errors[0].Message
          );
        } else {
          Messages.Notify.error(
            "An error occurred while executing the communication"
          );
        }
      }
    
  };

  private announcementInsertRequest = () => {
    let blankModel: Models.IAnnouncementViewModel = {
      Index: 0,
      IsFirst: false,
      IsLast: false,
      Comments: [],
      NumberOfImpressions: 0,
      Rating: 0,
      AllTags: [],
      SelectedTags: [],
      AssociatedTitles: [],
      Announcement: {
        Title: "",
        Content: "",
        CreationDate: new Date(Date.now()),
        LastModificationDate: new Date(Date.now()),
        PublisherId: -1,
        AllowComments: false,
        IsPublic: false,
        PublishBy: new Date(Date.now()),
        TableId: -1,
        TableGuid: "00000000-0000-0000-0000-000000000000",
        AllowScoreDisplay: false,
      }
    };
    this.setState({
      drawerShow: true,
      currentDrawerContent: (
        <AnnouncementForm
          initialNode={blankModel}
          saveRequested={this.saveAnnouncement}
          reloadAnnouncements={this.reloadAnnouncements}
          isEditing={false}
        />
      )
    });
  };

  private generateAnnouncement = (n: INode): JSX.Element => {
    let node = n as Models.IAnnouncementViewModel;

    let attrs: any = {};
    attrs[
      Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute
    ] = node.TableId;
    attrs[
      Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute
    ] = node.Index;
    let dataItems = [];
    dataItems.push(
      <DataItem flexVal={2} key={1} value={node.Announcement.Title} />
    );
    dataItems.push(
      <DataItem
        flexVal={1}
        key={2}
        className="leftBorder"
        value={node.Comments.length.toString()}
      />
    );
    dataItems.push(
      <DataItem
        flexVal={2}
        key={3}
        className="leftBorder"
        value={Convert.dateToFormattedString(
          node.Announcement.CreationDate,
          Languages.English
        )}
      />
    );
    dataItems.push(
      <DataItem
        flexVal={2}
        key={4}
        className="leftBorder"
        value={Convert.dateToFormattedString(
          node.Announcement.LastModificationDate,
          Languages.English
        )}
      />
    );
    dataItems.push(
      <DataItem
        flexVal={1}
        key={5}
        className="leftBorder"
        value={node.CurrentRatingScore.toString()}
      />
    );
    dataItems.push(
      <DataItem
        flexVal={1}
        key={6}
        className="leftBorder"
        value={node.NumberOfImpressions.toString()}
      />
    );

    return (
      <DataRow
        node={node}
        key={node.TableId}
        attributes={attrs}
        dataItems={dataItems}
        rowEditRequested={this.announcementEditRequest}
      />
    );
  };

  private announcementEditRequest = (eNode: INode) => {
    let e = eNode as Models.IAnnouncementViewModel;
    this.setState({
      drawerShow: true,
      currentDrawerContent: (
        <AnnouncementForm
          initialNode={e}
          saveRequested={this.saveAnnouncement}
          deleteRequested={this.deleteAnnouncement}
          reloadAnnouncements={this.reloadAnnouncements}
          isEditing={true}
        />
      )
    });
  };

  reloadAnnouncements = () => {
    this.announcementsTable.current!.reload();
  };

  private titleEditRequest = (e: INode) => {
    this.setState(
      {
        selectedTitle: e as Models.ITitle,
      },
      () => {
        this.titlesTable.current!.reRender();
        this.announcementsTable.current!.reload();
      }
    );
  };

  private titleFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowTitles({
        FlowRequest: request.Batches[0],
        PublisherId: this.state.selectedPublisher,
        ExcludeTitles: [],
        HideInactiveTitles: false,
      });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });

  private initializeTitle = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      let result = await this.context.flowTitles({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
        PublisherId: this.state.selectedPublisher,
        ExcludeTitles: [],
        HideInactiveTitles: false,
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });

  private generateTitle = (n: INode): JSX.Element => {
    let node = n as Models.ITitle;
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    let dataItems = [];
    dataItems.push(<DataItem flexVal={2} key={1} value={node.Name} />);
    dataItems.push(<DataItem flexVal={1} key={2} className="leftBorder" value={Models.TitleStatus[node.TitleStatus]} />);
    dataItems.push(<DataItem flexVal={2} key={3} className="leftBorder" value={Convert.dateToFormattedString(node.PublicationDate, Languages.English)} />);
    dataItems.push(<DataItem flexVal={2} key={4} className="leftBorder" value={Convert.dateToFormattedString(node.ExpirationDate, Languages.English)} />);

    return (
      <DataRow
        className={this.state.selectedTitle !== null && this.state.selectedTitle.TableId === node.TableId ? " selected" : ""}
        node={node}
        key={node.TableId}
        attributes={attrs}
        dataItems={dataItems}
        rowEditRequested={this.titleEditRequest}
      />
    );
  };

  private titleQueryExecute = (query: string) => {
    this.titlesTable.current!.reload(query);
  };

  render() {
    if (
      !this.props.IsLoggedIn ||
      this.state.allowedPubs.length <= 0 ||
      !this.context.viewedViews.get(DashboardView.Announcements)!.isLoaded()
    ) {
      return "";
    }
    let settings = JSON.parse(JSON.stringify(Models.genericDataSettings));
    settings.batchSize = 25;
    settings.maxHeight = "300px";
    return (
      <div className="mainView" style={{ display: "flex" }}>
        <PanelView
          publisherList={this.state.allowedPubs}
          publisherChanged={this.publisherChanged}
          showAdd={false}
          selectedPublisher={this.state.currentPub!}
          disabled={this.state.panelDisabled}
        />
        
              {this.context.canManageAnnouncements(this.state.selectedPublisher) && (
        <div className="announcementView full-height full-width">
          {/* <Loading className="full-width full-height" isLoading={this.state.loading} theme="opaque" status="Loading Titles..."> */}
          <DrawerContainer
            direction="top"
            className="flex-fill d-flex flex-column full-height"
          >
            <Drawer
              onBackdropClicked={() => {
                this.setState({
                  drawerShow: false,
                  currentDrawerContent: null
                });
              }}
              isOpen={this.state.drawerShow}
              backdrop={true}
              className="details-view"
            >
              {this.state.currentDrawerContent}
            </Drawer>
            <div className="announcementViewInner">
            
              <div className="section">
                <h1>Welcome to the Announcement View</h1>
                <p>
                  Welcome to the Announcement View. This view is used to manage
                  the various announcements in the system. In this view you can:
                </p>
                <ul>
                  <li>Create new announcements</li>
                  <li>Modify Existing announcements</li>
                  <li>Delete Existing announcements</li>
                  <li>Moderate comments under each announcement</li>
                </ul>
              </div>
              {this.context.canManageAnnouncements(this.state.selectedPublisher) && (
                <div className="section">
                  <h2>Titles</h2>
                  <p> This list the titles of the selected publisher. </p>
                  <DataTable
                    headers={["Name", "Status", "Publication Date", "Expiration Date"]}
                    headerFlexes={[2, 1, 2, 2]}
                    flowProvider={this.titleFlowProvider}
                    initializeFlowProvider={this.initializeTitle}
                    objectBuilder={this.generateTitle}
                    ref={this.titlesTable}
                    settingsOverride={settings}
                    searchQueryComitted={this.titleQueryExecute}
                  />
                </div>
              )}
              {this.context.canManageAnnouncements(this.state.selectedPublisher) && (
              <div className="section">
                 <h2>Announcements</h2>
                  <p> This list the announcements of the selected publisher. </p>
                <DataTable
                  headers={[
                    "Title",
                    "Number of Comments",
                    "Creation Date",
                    "Last Modification Date",
                    "Current Rating Score",
                    "Number of Impressions"
                  ]}
                  headerFlexes={[2, 1, 2, 2, 1, 1]}
                  rowAddRequested={this.announcementInsertRequest}
                  flowProvider={this.announcementFlowProvider}
                  initializeFlowProvider={this.initializeAnnouncements}
                  objectBuilder={this.generateAnnouncement}
                  ref={this.announcementsTable}
                  settingsOverride={Models.genericDataSettings}
                />
              </div>)}
            </div>
          </DrawerContainer>
          {/* </Loading> */}
        </div>)}
      </div>
    );
  }
}
